import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const FAQContainer = styled(Box)`
  width: 60%;
  margin: 0 auto;
  margin-top: 18vw;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3vw;
  align-items: center;
  position: relative;
`;

export const TextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2vw;
  text-align: left;
`;

export const TextContainerTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.1vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1.2;
    margin-top: 1.5vw;
    padding: 0.5vw;
  }
`;

export const AccordionContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AccordionElement = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  border-top: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
  padding: 1vw 0;
  border-bottom: ${({ variant, theme, $lastChild }) =>
    $lastChild ? `1px solid ${theme?.[variant]?.textColor}` : "none"};
`;

export const AccordionTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    padding: 0.5vw;
  }
`;

export const AccordionSubText = styled(Typography)`
  height: 0;
  &.accordion-open {
    height: 100%;
    padding: 1vw;
    &.MuiTypography-root {
      font-size: 1vw;
      font-weight: 400;
      color: ${({ variant, theme }) => theme?.[variant]?.textColor};
      font-family: "Standard", sans-serif;
      line-height: 1.2;
    }
  }
`;

export const TitleContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3vw;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const InverseLetters = styled.span`
  -webkit-text-fill-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ variant, theme }) =>
    theme?.[variant]?.textColor};
`;
