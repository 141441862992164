import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 14vw;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: black;
    font-size: 12vw;
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const StripedContainer = styled(Box)`
  width: 100%;
  height: 160vw;
  display: grid;
  grid-template-columns: repeat(33, 1fr);
  margin-top: 3vw;
  position: relative;
`;

export const StripedColumns = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ odd, variant, theme }) =>
    odd ? theme?.[variant]?.backgroundColor : theme?.[variant]?.textColor};
`;

export const CardContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 140vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 37vw;
  gap: 3.5vw;
`;

export const Card = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 88%;
  height: 100%;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  padding: 3vw;
`;

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  & svg {
    width: 15vw;
    height: 15vw;
    margin-left: -6px;
  }
`;

export const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    height: 100%;
    text-align: left;
    margin-top: 4vw;
    font-size: 5.2vw;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1.3;
    text-transform: uppercase;
  }
`;

export const CardTextContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 2.8vw;
    font-weight: 200;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1.2;
    text-align: left;
  }
`;

export const CardLink = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    text-align: right;
    font-size: 2.2vw;
    font-weight: 400;
    text-decoration: underline;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 0.8;
  }
`;

export const UnderlinedText = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 0.5vw;
    font-size: 2.2vw;
    font-weight: 400;
    text-decoration: underline;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    text-align: left;
    cursor: pointer;
  }
`;
