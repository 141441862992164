import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthService } from "../../service/authService";

export const register = createAsyncThunk("auth/register", async (payload) => {
  const { data } = await AuthService.register(payload);
  return data;
});

export const login = createAsyncThunk("auth/login", async (payload) => {
  const { data } = await AuthService.authenticate(payload);
  return data;
});

export const refreshToken = createAsyncThunk("auth/refreshToken", async () => {
  const { data } = await AuthService.refreshToken();
  return data;
});

export const tokensRevoke = createAsyncThunk("auth/tokensRevoke", async () => {
  const { data } = await AuthService.tokensRevoke();
  return data;
});
