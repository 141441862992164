import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 65vw;
  margin: 0 auto;
  margin-top: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 5vw;
`;

export const ImgContainer = styled(Box)`
  width: 100%;
  position: relative;
`;

export const TooltipContainer = styled(Box)`
  width: 6vw;
  position: absolute;
  top: 2vw;
  left: -1vw;
`;

export const TooltipText = styled(Typography)`
  &.MuiTypography-root {
    position: absolute;
    top: 4.4vw;
    left: 1vw;
    font-size: 0.4vw;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const AchievementsContainer = styled(Box)`
  width: 2.2vw;
  position: absolute;
  top: 0.8vw;
  left: 2vw;
`;

export const TextAndTitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1vw;
  text-align: left;
`;

export const TextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5vw;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 2.5vw;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.1vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const SignUpButton = styled(Typography)`
  &.MuiTypography-root {
    width: fit-content;
    font-size: 1.1vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    border-radius: 0.5vw;
    padding: 1vw 4.5vw;
    cursor: pointer;
    align-self: left;
    justify-self: flex-end;
  }
`;

