import { useEffect, useState } from "react";

export const usePosition = () => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition(pos.coords);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return position;
};
