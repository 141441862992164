import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getActivities,
  getUser,
  markActivity,
  postEvent,
  resetPassword,
  resetPasswordComplete,
  updateMail,
  updatePassword,
} from "../../store/userSlice/userAsyncThunk";

const initialState = {
  loading: false,
  id: null,
  points: null,
  mailAddress: null,
  registeredAt: null,
  activities: [],
  language: "en",
  instructionsModal: true,
  selectedEvent: null,
  fromReward: false,
  signUpModal: "",
  eventRegistered: false,
  qrModal: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload);
    },
    setInstructionsModal: (state, action) => {
      state.instructionsModal = action.payload;
      localStorage.setItem("instructionsModal", action.payload);
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setFromReward: (state, action) => {
      state.fromReward = action.payload;
    },
    setSignUpModal: (state, action) => {
      state.signUpModal = action.payload;
    },
    setQrModal: (state, action) => {
      state.qrModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.id = action.payload.id;
      state.points = action.payload.points;
      state.mailAddress = action.payload.mailAddress;
      state.registeredAt = action.payload.registeredAt;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state = initialState;
    });
    builder.addCase(updateMail.fulfilled, (state, action) => {
      state = action.payload;
    });
    builder.addCase(resetPassword.fulfilled);
    builder.addCase(resetPasswordComplete.fulfilled);
    builder.addCase(updatePassword.fulfilled);
    builder.addCase(getActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.activities = action.payload?.sort((a, b) =>
        a.id.localeCompare(b.id)
      );
    });
    builder.addCase(getActivities.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(markActivity.fulfilled);
    builder.addCase(postEvent.fulfilled, (state) => {
      state.eventRegistered = true;
      window.localStorage.setItem("eventRegistered", true);
    });
  },
});

export const {
  setUser,
  setLanguage,
  setInstructionsModal,
  setSelectedEvent,
  setFromReward,
  setSignUpModal,
  setQrModal,
} = userSlice.actions;
export default userSlice.reducer;
