import { t } from "i18next";
import React from "react";
import VideoSource from "../../../assets/HeartCGI.mp4";
import DirectionTitleAndText from "../DirectionTitleAndText/DirectionTitleAndText";
import SignupBanner from "../SignupBanner/SignupBanner";
import { ImgContainer, VideoContainer } from "./style";

const Video = ({ variant, onClickEnterFlxEmailHandler }) => {
  return (
    <VideoContainer>
      <DirectionTitleAndText
        variant={variant}
        textDirection={"row"}
        title={["THE", "EXPERIENCE"]}
        text={t("experienceText").split("BREAK")}
        width={"95%"}
        textAlign={"flex-start"}
        titleSize={"5vw"}
      />
      <ImgContainer>
        <video width="100%" height="100%" controls>
          <source src={VideoSource} type="video/mp4" />
        </video>
      </ImgContainer>
      <SignupBanner
        onClickEnterFlxEmailHandler={onClickEnterFlxEmailHandler}
        variant={variant}
      />
    </VideoContainer>
  );
};

export default Video;
