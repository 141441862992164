import { Html } from "@react-three/drei";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PathButton from "./PathButton";
import PlayButton from "./PlayButton";
import QRButton from "./QRButton";
import {
  Details,
  Info,
  Separator,
  Title,
  TooltipLeft,
  TooltipRight,
  TooltipWrapper,
  Wrapper,
} from "./style";

const Tooltip = ({
  locationId,
  title,
  address,
  points,
  position,
  onActionClicked,
  visible,
}) => {
  const [isInRange, setIsInRange] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const activity = useSelector((state) => state.user)?.activities.find(
    (data) => data.id === locationId
  );

  /* eslint-disable */
  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        navigator.geolocation.getCurrentPosition(
          (coords) => {
            setIsInRange(
              Math.sqrt(
                Math.pow(coords?.coords?.latitude - activity?.lat, 2) +
                Math.pow(coords?.coords?.longitude - activity?.lon, 2)
              ) <= 0.01
            );
          },
          (err) => console.warn(err)
        );
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [visible]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <Html
      position={[position.x, 1.1, position.z]}
      distanceFactor={1}
      style={{ transform: "scale(4)" }}
    >
      <Wrapper>
        <TooltipWrapper style={{ display: visible ? "flex" : "none" }}>
          <TooltipLeft>
            <Title
              style={{
                fontFamily: "Classic,sans-serif",
              }}
            >
              {title}
            </Title>
            <Details>
              <svg fill="none" stroke="#000" viewBox="0 0 17 22" height={28}>
                <path d="M13.874 7.19a5.375 5.375 0 0 0-10.748 0c0 .874.215 1.696.583 2.426h-.003l4.792 8.649 4.793-8.649h-.004c.372-.73.583-1.552.583-2.425h.004Z" />
                <path d="M10.548 7.112a2.05 2.05 0 1 1-4.1.001 2.05 2.05 0 0 1 4.1 0ZM4.587 16.867c-1.873.36-3.108.97-3.108 1.663 0 1.107 3.142 2.003 7.021 2.003 3.88 0 7.022-.896 7.022-2.003 0-.692-1.232-1.303-3.108-1.663" />
              </svg>
              <Info>
                <span
                  style={{
                    fontFamily: "Standard,sans-serif",
                  }}
                >
                  {address}
                </span>
                <span
                  style={{
                    fontFamily: "Standard,sans-serif",
                  }}
                >
                  {points} points
                </span>
              </Info>
            </Details>
          </TooltipLeft>
          <Separator> </Separator>
          <TooltipRight
            onClick={() =>
              onActionClicked?.(
                isMobile ? (isInRange ? "ar" : "navigate") : "qr",
                locationId
              )
            }
          >
            {isMobile ? (
              isInRange ? (
                <>
                  <PlayButton />
                  <p>Start Ar</p>
                </>
              ) : (
                <>
                  <PathButton />
                  <p>Navigate</p>
                </>
              )
            ) : (
              <>
                <QRButton />
                <p>Scan Me</p>
              </>
            )}
          </TooltipRight>
        </TooltipWrapper>
      </Wrapper>
    </Html>
  );
};

Tooltip.propTypes = {
  locationId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  position: PropTypes.object,
  onActionClicked: PropTypes.func,
  isInRange: PropTypes.bool,
  visible: PropTypes.bool,
};

export default Tooltip;
