import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import { AvailableFlags } from "../../../utils/utils";

const LanguageModal = ({ open }) => {
  const dispatch = useDispatch();
  const { signUpModal } = useSelector((state) => state.user);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth < 600) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });
    };
  });
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "80%",
          maxWidth: 600,
          margin: "0 auto",
          backgroundColor: "#0f1012",
        },
      }}
      open={open}
      onClose={() => dispatch(setSignUpModal(""))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: "white",
          fontFamily: "Standard, sans-serif",
          fontSize: !isMobile ? "2vw" : "5vw",
        }}
        id="alert-dialog-title"
      >
        {t("chooseLanguage")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateRows: "repeat(3,1fr)",
            rowGap: "3vw",
            columnGap: ".5vw",
            alignItems: "center",
          }}
        >
          {AvailableFlags(isMobile).map((data) => (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                dispatch(setSignUpModal(""));
                window.open(
                  `https://www.footlocker.${data.label}/${
                    data.label !== "co.uk" ? data.label : "en"
                  }/${signUpModal}`,
                  "_blank"
                );
              }}
            >
              {data.flag}
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LanguageModal;
