import React from "react";
import { Text, TextAndTitleContainer, Title, TitleContainer } from "./style";

const DirectionTitleAndText = ({
  width,
  variant,
  textDirection,
  title,
  text,
  textAlign,
  titleSize,
  textSize,
  alignItems,
  textMargin,
  titleMargin,
  titleHeight,
}) => {
  return (
    <TextAndTitleContainer
      alignItems={textAlign}
      variant={variant}
      direction={textDirection}
      width={width}
    >
      <TitleContainer titleMargin={titleMargin} alignItems={alignItems}>
        {title.map((data) => (
          <Title
            titleHeight={titleHeight}
            titleSize={titleSize}
            variant={variant}
          >
            {data}
          </Title>
        ))}
      </TitleContainer>
      {text.length ? (
        text.map((data) => (
          <Text textMargin={textMargin} textSize={textSize} variant={variant}>
            {data}
          </Text>
        ))
      ) : (
        <Text textMargin={textMargin} textSize={textSize} variant={variant}>
          {text}
        </Text>
      )}
    </TextAndTitleContainer>
  );
};

export default DirectionTitleAndText;
