import { Box, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import PropTypes from "prop-types";
import { useRef } from "react";
import { setSelectedEvent } from "../../store/userSlice/userSlice";
import { useDispatch } from "react-redux";

const Pin = ({ locationId, position, visible }) => {
	const { nodes } = useGLTF("./map_assets/referee.glb");
	const ref = useRef();

	useFrame((_, delta) => {
		if (ref.current && visible) {
			ref.current.rotation.y += delta;
		}
	});

	const dispatch = useDispatch();

	return (
		<Box
			args={[0.7, 1, 0.7]}
			position={[position.x, 0.7, position.z]}
			rotation={[0, 0, 0]}
			scale={0.6}
			onClick={() => dispatch(setSelectedEvent(locationId))}
			material-alphaTest={2}
		>
			<group ref={ref} scale={10} visible={visible} position={[0, -0.5, 0]}>
				<mesh geometry={nodes.Mesh001.geometry}>
					<meshBasicMaterial color="black" />
				</mesh>
				<mesh geometry={nodes.Mesh001_1.geometry}>
					<meshBasicMaterial color="white" />
				</mesh>
				<mesh geometry={nodes.Mesh001_2.geometry}>
					<meshBasicMaterial color="white" />
				</mesh>
			</group>
		</Box>
	);
};

Pin.propTypes = {
	locationId: PropTypes.string.isRequired,
	position: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	visible: PropTypes.bool,
};

export default Pin;
