import axios from "axios";

export const api = axios.create({
  baseURL: "https://footlocker-olympics.prd.services.senseapps.cloud",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 10000,
  withCredentials: true,
  responseType: "json",
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
