import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const AboutContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  margin-top: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AboutText = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    text-align: center;
    font-size: 9vw;
    font-weight: 800;
    font-family: "Classic", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-transform: uppercase;
    line-height: 0.8;
  }
`;

export const AboutElementsContainer = styled(Box)`
  margin-top: 10vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 5vw;
`;

export const AboutElement = styled(Box)`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6vw;
  padding: 3.5vw;
`;

export const AboutElementText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 4vw;
    text-align: center;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const AboutElementIcon = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 14vw;
    height: 14vw;
  }
`;
