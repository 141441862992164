import React, { useEffect, useState, useRef } from "react";
import {
  PointsBarContainer,
  PointsBarValueContainer,
  PointsBarText,
  PointsBarValue,
} from "./style";
import { useSelector } from "react-redux";
import { MAX_POINTS } from "../../../utils/utils";

const PointsBar = ({ variant }) => {
  const { points } = useSelector((state) => state.user);
  const [maxWidth, setMaxWidth] = useState(0);
  const pointsRef = useRef(null);

  useEffect(() => {
    const ref = pointsRef.current;
    if (ref) {
      setMaxWidth(ref.offsetWidth);
    }
    window.addEventListener("resize", () => {
      if (ref) {
        setMaxWidth(ref.offsetWidth);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {
        if (ref) {
          setMaxWidth(ref.offsetWidth);
        }
      });
    };
  }, []);

  return (
    <PointsBarContainer variant={variant} ref={pointsRef}>
      <PointsBarValueContainer>
        <PointsBarValue
          rounded={points < 45000 ? true : false}
          width={(points * maxWidth) / MAX_POINTS}
          maxWidth={maxWidth}
        >
          <PointsBarText variant={variant}>{points} XP</PointsBarText>
        </PointsBarValue>
      </PointsBarValueContainer>
      <PointsBarValueContainer
        sx={{
          position: "absolute",
          top: 0,
        }}
        align={"end"}
      >
        <PointsBarText color={"#707070"} variant={variant}>
          {MAX_POINTS} XP
        </PointsBarText>
      </PointsBarValueContainer>
    </PointsBarContainer>
  );
};

export default PointsBar;
