import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const MapContainer = styled(Box)`
  width: 100%;
  position: relative;
`;

export const MapImage = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ImgContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled(Box)`
  position: absolute;
  top: 7.5vw;
  left: 42vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: black;
    font-size: ${({ textSize }) => textSize || "3vw"};
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
  }
`;

export const Container = styled(Box)`
  position: absolute;
  top: 20vw;
  left: 24.5vw;
  width: 50vw;
  display: flex;
  flex-direction: column;
`;

export const CountdownContainer = styled(Box)`
  width: fit-content;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CountdownValue = styled(Box)`
  width: 10vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Countdown = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 8.5vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
    line-height: 0.8;
  }
`;

export const CountdownSubtitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 1.8vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
    line-height: 1.2;
  }
`;

export const MiddleText = styled.span`
  -webkit-text-fill-color: ${({ variant, theme }) =>
    theme?.[variant]?.strokeColor};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ variant, theme }) =>
    theme?.[variant]?.textColor};
`;

export const StyledColumn = styled(Typography)`
  &.MuiTypography-root {
    font-size: 8.5vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;
