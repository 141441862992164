import { Backdrop, CircularProgress } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Earn } from "../../../assets/Earn.svg";
import { usePosition } from "../../../hooks/usePosition";
import { refreshToken } from "../../../store/authSlice/authAsyncThunk";
import {
    setAccessToken,
    setExpiresAt,
} from "../../../store/authSlice/authSlice";
import {
    getActivities,
    getUser,
    markActivity,
    postEvent,
} from "../../../store/userSlice/userAsyncThunk";
import {
    setFromReward,
    setLanguage,
    setSelectedEvent,
} from "../../../store/userSlice/userSlice";
import { AvailableLanguages } from "../../../utils/utils";
import MobileLogo from "../MobileLogo/MobileLogo";
import {
    LanguageElement,
    LanguageSelectorContainer,
    LogoContainer,
} from "../PlaceholderMap/style";
import {
    Button,
    ButtonContainer,
    ImageAndTextContainer,
    ImageContainer,
    RewardContainer,
    Text,
} from "./style";

const NewReward = () => {
    const variant = "dark";
    const activityId = useSearchParams()[0].get("activityId");
    const { loading, id, language, activities } = useSelector(
        (state) => state.user
    );

    const [eventSent, setEventSent] = useState(false);

    const { expiresAt } = useSelector((state) => state.auth);
    const activity = activities && activities.find(
        (data) => parseInt(data?.id?.split("ACT")?.[1]) === parseInt(activityId)
    );
    const [relocate, setRelocate] = useState(false);

    const dispatch = useDispatch();
    const [activityLoaded, setActivityLoaded] = useState(false);
    const [languages, setLanguages] = useState(
        AvailableLanguages.map((data) => ({
            ...data,
            selected: language === data.key,
        }))
    );

    const onLanguageClickHandler = (value) => {
        setLanguages((prevLanguages) =>
            prevLanguages.map((data) => {
                if (data.key === value) {
                    return { ...data, selected: true };
                } else {
                    return { ...data, selected: false };
                }
            })
        );
        dispatch(setLanguage(value));
    };

    /* eslint-disable */
    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        const expiresAt = localStorage.getItem("expiresAt");
        const language = localStorage.getItem("language");
        if (language) {
            dispatch(setLanguage(language));
        } else {
            dispatch(setLanguage("en"));
        }
        if (accessToken && expiresAt && new Date() < new Date(expiresAt)) {
            dispatch(setAccessToken(accessToken));
            dispatch(setExpiresAt(expiresAt));
            dispatch(getUser());
            dispatch(getActivities())
                .then(() => {
                    setActivityLoaded(true);
                })
        } else if (accessToken) {
            dispatch(refreshToken()).then(() => {
                dispatch(getUser());
                dispatch(getActivities())
                    .then(() => {
                        setActivityLoaded(true);
                    })
            });
        }
    }, []);

    useEffect(() => {
        if (!activities.length) dispatch(getActivities()).then(
            () => setActivityLoaded(true)
        )
    }, [activities]);

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const navigate = useNavigate();

    const position = usePosition();

    const isLoggedIn = !!id && new Date(expiresAt) > Date.now();

    const onClickThanksHandler = () => {
        navigate("/");
    };

    const calcDistanceFromEvent = (event) => {
        if (event) {
            const { lat, lon } = event;
            const { latitude: userLat, longitude: userLon } = position ?? {
                latitude: 0,
                longitude: 0,
            };
            return Math.sqrt(Math.pow(userLat - lat, 2) + Math.pow(userLon - lon, 2));
        }
    };

    const onClickNextOneHandler = () => {
        if (isLoggedIn) {
            dispatch(
                setSelectedEvent(
                    activities
                        .filter((data) => data?.id !== activity?.id)
                        .filter((data) => !data.rewardedAt)
                        .sort(
                            (a, b) => calcDistanceFromEvent(a) - calcDistanceFromEvent(b)
                        )[0]?.id
                )
            );
            setRelocate(true);
        } else {
            navigate(`/`);
        }
    };

    useEffect(() => {
        if (relocate) {
            dispatch(setFromReward(true));
            navigate(`/`);
        }
    }, [relocate]);


    useEffect(() => {
        if (!!position && activityLoaded && !eventSent) {
            if (activity) {
                if (isLoggedIn) {
                    dispatch(
                        postEvent({
                            eventType: "reward",
                            userAgent: navigator.userAgent,
                            language: navigator.language,
                        })
                    ).then(() => setEventSent(true));
                } else {
                    dispatch(
                        postEvent({
                            eventType: "notLoggedInReward",
                            userAgent: navigator.userAgent,
                            language: navigator.language,
                        })
                    ).then(() => setEventSent(true));
                }
            }
        }
    }, [position, activityLoaded, isLoggedIn, eventSent]);

    useEffect(() => {
        if (!!activity && isLoggedIn && !loading) {
            if (!activity.rewardedAt) {
                dispatch(markActivity(activity?.id));
            }
        }
    }, [activity, isLoggedIn, loading])


    return (
        <>
            <LogoContainer variant={variant}>
                <MobileLogo />
                <LanguageSelectorContainer>
                    {languages.map((language) => (
                        <LanguageElement
                            key={language.label}
                            variant={variant}
                            selected={language.selected}
                            onClick={() => onLanguageClickHandler(language.key)}
                        >
                            {language.icon}
                        </LanguageElement>
                    ))}
                </LanguageSelectorContainer>
            </LogoContainer>
            <RewardContainer variant={variant}>
                {(
                    loading ? (
                        <Backdrop open>
                            <CircularProgress
                                sx={{ color: "white", margin: "0 auto" }}
                                size="5rem"
                            />
                        </Backdrop>
                    ) : (
                        <>
                            <ImageAndTextContainer>
                                <ImageContainer>
                                    <Earn sx={{ width: "100%", height: "100%" }} />
                                </ImageContainer>
                                <Text variant={variant}>
                                    {isLoggedIn
                                        ? `${t("earnedPoints").split("$")[0]} ${activity?.points} ${t("earnedPoints").split("$")[1]
                                        }`
                                        : t("noLoggedEarnedPoints")}
                                </Text>
                            </ImageAndTextContainer>
                            <ButtonContainer>
                                <Button onClick={onClickThanksHandler} variant={variant}>
                                    {isLoggedIn ? t("thanks") : t("signUp")}
                                </Button>
                                <Button onClick={onClickNextOneHandler} variant={variant}>
                                    {isLoggedIn ? t("nextOne") : t("noThanks")}
                                </Button>
                            </ButtonContainer>
                        </>
                    )
                )}
            </RewardContainer>
        </>
    );
};

export default NewReward;
