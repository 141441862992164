import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  AchievementsContainer,
  Container,
  ImageContainer,
  SignUpButton,
  Text,
  TextContainer,
  TextWithTitleContainer,
  Title,
  TitleContainer,
  TooltipContainer,
  TooltipText,
} from "./style";

const Points = ({ variant }) => {
  const { id } = useSelector((state) => state.user);
  const { expiresAt } = useSelector((state) => state.auth);
  const isLoggedIn = !!id && new Date(expiresAt) > Date.now();
  const dispatch = useDispatch();
  return (
    <Container>
      <ImageContainer>
        <img
          style={{ width: "100%" }}
          src={require(`../../../assets/Mobile/PeopleWatching.png`)}
          alt=""
        />
        <TooltipContainer>
          <img
            style={{ width: "100%" }}
            src={require("../../../assets/Tooltip.png")}
            alt="Tooltip"
          />
          <AchievementsContainer>
            <img
              style={{ width: "100%" }}
              src={require("../../../assets/Achievements.png")}
              alt="Achievements"
            />
            <TooltipText variant={variant}>
              {t("earned500Points")
                .split("BREAK")
                .map((data) => (
                  <>
                    {data}
                    <br />
                  </>
                ))}
            </TooltipText>
          </AchievementsContainer>
        </TooltipContainer>
      </ImageContainer>
      <TextWithTitleContainer>
        <TitleContainer>
          {t("flxYourExperience")
            .split("BREAK")
            .map((data) => (
              <Title key={data} variant={variant}>
                {data.toLocaleUpperCase()}
              </Title>
            ))}
        </TitleContainer>
        <TextContainer>
          {t("getMore")
            .split("BREAK")
            .map((data) => (
              <Text variant={variant} key={data}>
                {data}
              </Text>
            ))}
        </TextContainer>
        {!isLoggedIn && (
          <SignUpButton
            onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
            variant={variant}
          >
            {t("signUp")}
          </SignUpButton>
        )}
      </TextWithTitleContainer>
    </Container>
  );
};

export default Points;
