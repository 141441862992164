import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const FooterContainer = styled(Box)`
  width: 90%;
  margin: 0 auto;
  margin-top: 20vw;
  padding: 8vw;
  display: flex;
  flex-direction: column;
  gap: 5vw;
  border-top: 1px solid ${({ theme, variant }) => theme?.[variant]?.textColor};
`;

export const LogoContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2vw;
  align-self: center;
  margin-bottom: 8vw;
`;

export const Logo = styled(Box)`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 8vw;
    font-weight: 400;
    font-family: "Classic", sans-serif;
    text-transform: uppercase;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    line-height: 1;
  }
`;

export const GridContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 10vw;
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
`;

export const GridElement = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 1.5vw;
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
`;

export const GridTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: vw;
    font-weight: 400;
    font-family: "Classic", sans-serif;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 1vw;
  }
`;

export const GridLink = styled(Typography)`
  &.MuiTypography-root {
    font-size: 5vw;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const SocialMediaContainer = styled(Box)`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  & > * {
    cursor: pointer;
  }
`;
