import { Backdrop, CircularProgress } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/userSlice/userSlice";
import {
  ACTIVITY_DICTIONARY,
  AvailableLanguages,
  SwitcherModes,
} from "../../../utils/utils";
import About from "../About/About";
import DesktopLogo from "../DesktopLogo/DesktopLogo";
import FAQ from "../FAQ/FAQ";
import FLXExperience from "../FLXExperience/FLXExperience";
import Footer from "../Footer/Footer";
import HowItWorks from "../HowItWorks/HowItWorks";
import PointsBar from "../PointsBar/PointsBar";
import Prizes from "../Prizes/Prizes";
import SwitcherBanner from "../SwitcherBanner/SwitcherBanner";
import Video from "../Video/Video";
import {
  LanguageElement,
  LanguageFlag,
  LanguageSelectorContainer,
  LanguageText,
  LogoContainer,
} from "./style";

const DesktopView = ({ variant, started }) => {
  const { loading, id, language, activities, selectedEvent } = useSelector(
    (state) => state.user
  );
  const { expiresAt } = useSelector((state) => state.auth);
  const isLoggedIn = !!id && new Date(expiresAt) > Date.now();
  const [switcherModes, setSelectedSwitcherModes] = useState(
    SwitcherModes(false, isLoggedIn).map((data) => ({
      ...data,
      selected: data.label === "AR Experience" && true,
    }))
  );

  const onClickSwitchHandler = (data) => {
    setSelectedSwitcherModes(() =>
      SwitcherModes(false, isLoggedIn).map((switcherMode) => ({
        ...switcherMode,
        selected: switcherMode.label === data,
      }))
    );
  };

  const [languages, setLanguages] = useState();

  useEffect(() => {
    setLanguages(() =>
      AvailableLanguages.map((data) => ({
        ...data,
        selected: language === data.key,
      }))
    );
  }, [language]);

  const dispatch = useDispatch();

  const onLanguageClickHandler = (value) => {
    setLanguages((prevLanguages) =>
      prevLanguages.map((data) => {
        if (data.key === value) {
          return { ...data, selected: true };
        } else {
          return { ...data, selected: false };
        }
      })
    );
    dispatch(setLanguage(value));
  };

  const onClickEnterFlxEmailHandler = () => {
    window.scrollTo(0, 0);
    onClickSwitchHandler("Profile");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [switcherModes]);

  return (
    <>
      <LogoContainer>
        <DesktopLogo />
        <LanguageSelectorContainer>
          {languages?.map((language) => (
            <LanguageElement
              key={language.label}
              variant={variant}
              selected={language.selected}
              onClick={() => onLanguageClickHandler(language.key)}
            >
              <LanguageText variant={variant}>
                {t(`language${language.label}`)}
              </LanguageText>
              <LanguageFlag>{language.icon}</LanguageFlag>
            </LanguageElement>
          ))}
        </LanguageSelectorContainer>
      </LogoContainer>
      <>
        {React.cloneElement(
          switcherModes?.find((data) => data?.selected)?.component,
          {
            started: started,
            variant: variant,
            onClickSwitchHandler: onClickSwitchHandler,
            selectedEvent: selectedEvent,
            locations: activities?.map((activity) => ({
              id: activity?.id,
              lat: activity.lat,
              long: activity.lon,
              name: ACTIVITY_DICTIONARY.find(
                (data) => data?.id === activity?.id
              )?.label,
              address: ACTIVITY_DICTIONARY.find(
                (data) => data?.id === activity?.id
              )?.address,
              link: ACTIVITY_DICTIONARY.find(
                (data) => data?.id === activity?.id
              )?.link,
            })),
          }
        )}
        {isLoggedIn && started && <PointsBar variant={variant} />}
        <SwitcherBanner
          switcherModes={switcherModes}
          variant={variant}
          onClickSwitchHandler={onClickSwitchHandler}
        />
        <Backdrop open={loading}>
          <CircularProgress
            sx={{ color: "white", margin: "0 auto" }}
            size="5rem"
          />
        </Backdrop>
      </>
      <Video
        onClickEnterFlxEmailHandler={onClickEnterFlxEmailHandler}
        variant={variant}
        started={started}
      />
      <HowItWorks variant={variant} />
      <About variant={variant} />
      <FLXExperience variant={variant} />
      <Prizes variant={variant} />
      <FAQ variant={variant} />
      <Footer variant={variant} />
    </>
  );
};

export default DesktopView;
