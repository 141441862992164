import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "../../service/userService";

export const getUser = createAsyncThunk("user/getUser", async () => {
  const { data } = await UserService.getUser();
  return data;
});

export const deleteUser = createAsyncThunk("user/deleteUser", async () => {
  await UserService.deleteUser();
});

export const updateMail = createAsyncThunk(
  "user/updateMail",
  async (payload) => {
    const { data } = await UserService.updateMail(payload);
    return data;
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (payload) => {
    const { data } = await UserService.updatePassword(payload);
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (payload) => {
    const { data } = await UserService.resetPassword(payload);
    return data;
  }
);

export const resetPasswordComplete = createAsyncThunk(
  "user/resetPasswordComplete",
  async (payload) => {
    const { data } = await UserService.resetPasswordComplete(payload);
    return data;
  }
);

export const getActivities = createAsyncThunk(
  "user/getActivities",
  async () => {
    const { data } = await UserService.getActivities();
    return data;
  }
);

export const markActivity = createAsyncThunk(
  "user/markActivity",
  async (payload) => {
    const { data } = await UserService.markActivity(payload);
    return data;
  }
);

export const postEvent = createAsyncThunk("user/postEvent", async (payload) => {
  const { data } = await UserService.postEvent(payload);
  return data;
});
