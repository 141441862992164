import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setQrModal } from "../../store/userSlice/userSlice";
import { t } from "i18next";

const QRModal = ({ open, experienceId }) => {
  const dispatch = useDispatch();
  const experienceSvg = require(`../../assets/Experience${experienceId}.svg`);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "80%",
          maxWidth: 600,
          margin: "0 auto",
          backgroundColor: "#0f1012",
        },
      }}
      open={open}
      onClose={() => dispatch(setQrModal(""))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: "white",
          fontFamily: "Standard, sans-serif",
          fontSize: !isMobile ? "1vw" : "3vw",
        }}
        id="alert-dialog-title"
      >
        {t("continueQR")}
      </DialogTitle>
      <DialogContent>
        <img src={experienceSvg} style={{ width: "100%" }} alt="experience" />
      </DialogContent>
    </Dialog>
  );
};

export default QRModal;
