import { Info } from "@mui/icons-material";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackgroundUrl from "../../../assets/GenericBackground.svg";
import { login, register } from "../../../store/authSlice/authAsyncThunk";
import {
  getActivities,
  getUser,
} from "../../../store/userSlice/userAsyncThunk";
import {
  ButtonContainer,
  EmailInput,
  ImageContainer,
  LoginContainer,
  SubmitButton,
  SubText,
  SubTextContainer,
  Text,
  TextContainer,
  DisclaimerContainer,
} from "./style";
import { setSignUpModal } from "../../../store/userSlice/userSlice";

export const Login = ({ variant, onClickSwitchHandler }) => {
  const { newUser, loginSuccess } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.user);
  const [emailValue, setEmailValue] = useState();
  const [enterClicked, setEnterClicked] = useState(false);
  const [emailCorrect, setEmailCorrect] = useState(false);

  const dispatch = useDispatch();

  const goToMapHandler = () => {
    onClickSwitchHandler("AR Experience");
  };

  /* eslint-disable */
  useEffect(() => {
    if (emailCorrect) {
      dispatch(login(emailValue));
    }
  }, [emailCorrect]);

  useEffect(() => {
    if (newUser) {
      dispatch(register(emailValue)).then(() => dispatch(login(emailValue)));
    }
  }, [newUser]);

  useEffect(() => {
    if (loginSuccess) {
      dispatch(getUser()).then(() => {
        dispatch(getActivities());
      });
    }
  }, [loginSuccess]);

  return (
    <ImageContainer $backgroundUrl={BackgroundUrl}>
      <LoginContainer>
        {!emailCorrect ? (
          <>
            <TextContainer>
              {enterClicked ? (
                <DisclaimerContainer>
                  <Text variant={variant}>{t("makeSure")}</Text>
                  <Tooltip title={t("disclaimer")}>
                    <Info
                      sx={{
                        color: "white",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </DisclaimerContainer>
              ) : (
                <DisclaimerContainer>
                  <Text variant={variant}>{t("emailConnection")}</Text>
                  <Tooltip title={t("disclaimer")}>
                    <Info
                      sx={{
                        color: "white",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </DisclaimerContainer>
              )}
              <ButtonContainer>
                <EmailInput
                  onChange={(e) => setEmailValue(e.target.value)}
                  value={emailValue}
                  placeholder="Email"
                  $appVariant={variant}
                />
                {enterClicked ? (
                  <SubmitButton
                    onClick={() => setEmailCorrect(true)}
                    variant={variant}
                  >
                    {t("itsCorrect")}
                  </SubmitButton>
                ) : (
                  <SubmitButton
                    onClick={() => setEnterClicked(true)}
                    variant={variant}
                  >
                    {t("enter")}
                  </SubmitButton>
                )}
              </ButtonContainer>
              <SubTextContainer>
                <SubText
                  variant={variant}
                  onClick={() => onClickSwitchHandler("AR Experience")}
                >
                  {t("cancel")}
                </SubText>
                <SubText
                  onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
                  variant={variant}
                >
                  {t("signUp")}
                </SubText>
              </SubTextContainer>
            </TextContainer>
          </>
        ) : (
          <>
            <TextContainer>
              <Text variant={variant}>{t("alreadyLogged")}</Text>
              <Text
                style={{
                  marginTop: "3vw",
                }}
                variant={variant}
              >
                {t("alreadyLoggedSubText")}
              </Text>
              <ButtonContainer>
                <SubmitButton onClick={goToMapHandler} variant={variant}>
                  {t("goToTheMap")}
                </SubmitButton>
              </ButtonContainer>
            </TextContainer>
          </>
        )}
      </LoginContainer>
      <Backdrop open={loading}>
        <CircularProgress
          sx={{ color: "white", margin: "0 auto" }}
          size="5rem"
        />
      </Backdrop>
    </ImageContainer>
  );
};
