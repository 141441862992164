import React from "react";
import {
  Text,
  TextAndTitleContainer,
  Title,
  TitleContainer,
  TextContainer,
} from "./style";

const DirectionTitleAndText = ({
  width,
  variant,
  textDirection,
  title,
  text,
  textAlign,
  titleSize,
}) => {
  return (
    <TextAndTitleContainer
      alignItems={textAlign}
      variant={variant}
      direction={textDirection}
      width={width}
    >
      <TitleContainer>
        {title.map((data) => (
          <Title titleSize={titleSize} variant={variant}>
            {data}
          </Title>
        ))}
      </TitleContainer>
      {text?.length > 0 ? (
        <TextContainer>
          {text.map((singleValue) => (
            <>
              <Text variant={variant}>{singleValue}</Text>
              {singleValue !== text[text.length - 1] && <br />}
            </>
          ))}
        </TextContainer>
      ) : (
        <Text variant={variant}>{text}</Text>
      )}
    </TextAndTitleContainer>
  );
};

export default DirectionTitleAndText;
