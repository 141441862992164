import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const PrizesContainer = styled(Box)`
  width: 90%;
  margin: 0 auto;
  margin-top: 30vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TextContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  gap: 5vw;
  text-align: left;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 10vw;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0px;
    font-family: "Classic", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-align: center;
    text-transform: uppercase;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    width: 90%;
    font-size: 4.5vw;
    font-weight: 400;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const ShowPrizes = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 6vw;
    width: fit-content;
    font-size: 4.5vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-style: normal;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    padding: 2.5vw 8vw;
    border-radius: 2vw;
    cursor: pointer;
  }
`;
