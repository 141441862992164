import { Box, Typography, TextField } from "@mui/material";
import styled from "styled-components";

export const ImageContainer = styled(Box)`
  width: 100%;
  background-image: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LoginContainer = styled(Box)`
  width: 60%;
  margin: 0 auto;
  margin-top: 8vw;
`;

export const TextContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    width: 80%;
    font-size: 1.5vw;
    text-align: center;
    font-weight: 400;
    color: ${({ theme, variant }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1.4;
  }
`;

export const ButtonContainer = styled(Box)`
  margin-top: 2vw;
  width: 50%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: 2vw;
`;

export const EmailInput = styled(TextField)`
  &.MuiFormControl-root {
    width: 100%;
    font-size: 3vw;
    font-weight: 800;
    font-family: "Standard", sans-serif;
    line-height: 1;
    border: 1px solid
      ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
    border-radius: 1vw;
    text-transform: uppercase;
    & .MuiInputBase-input {
      color: ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
      text-transform: uppercase;
      text-align: center;
      font-weight: 800;
      font-family: "Standard", sans-serif;
      padding: 1.5vw;
    }
  }
  & fieldset {
    border-width: 0px important;
    border-color: transparent !important;
  }
`;

export const SubmitButton = styled(Box)`
  width: 92%;
  font-size: 1.5vw;
  font-weight: 800;
  font-family: "Standard", sans-serif;
  line-height: 1;
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  font-family: "Standard", sans-serif;
  border-radius: 1vw;
  text-transform: uppercase;
  padding: 1.5vw;
  cursor: pointer;
`;

export const SubTextContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin-top: 2vw;
  margin-bottom: 10vw;
`;

export const SubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1vw;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    line-height: 1;
    color: ${({ theme, variant }) => theme?.[variant]?.textColor};
    text-decoration: underline;
  }
`;

export const DisclaimerContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
