import { t } from "i18next";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  SignupBannerContainer,
  SignupBannerText,
  SignupButton,
  SignupButtonContainer,
  SubText,
} from "./style";

const SignupBanner = ({ variant, onClickEnterFlxEmailHandler }) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);
  const { accessToken, expiresAt } = useSelector((state) => state.auth);
  const isLoggedIn = !!id && accessToken && new Date(expiresAt) > new Date();
  const started = new Date() > new Date(2024, 6, 26, 0, 0, 0, 0);
  return (
    <SignupBannerContainer>
      <SignupBannerText variant={variant}>
        {!started &&
          localStorage.getItem("registrationComplete") === "true" &&
          t("desktopRegistrationGreetings")
            .split("BREAK")
            .map((data) => (
              <>
                {data}
                <br />
              </>
            ))}
        {!started &&
          !isLoggedIn &&
          t("desktopLoginToGetMore")
            .split("BREAK")
            .map((data) => (
              <>
                {data}
                <br />
              </>
            ))}
      </SignupBannerText>
      {!isLoggedIn && !localStorage.getItem("registrationComplete") && (
        <SignupButtonContainer>
          <SignupButton
            onClick={() => onClickEnterFlxEmailHandler()}
            variant={variant}
          >
            {t("enterFLXMail")}
          </SignupButton>
          <SubText
            onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
            variant={variant}
          >
            {t("notFlxMember")}
          </SubText>
        </SignupButtonContainer>
      )}
    </SignupBannerContainer>
  );
};

export default SignupBanner;
