import { Add, Remove } from "@mui/icons-material";
import { t } from "i18next";
import React, { useState } from "react";
import { FAQs } from "../../../utils/utils";
import {
  AccordionContainer,
  AccordionElement,
  AccordionSubText,
  AccordionTitle,
  FAQContainer,
  TextContainer,
  Title,
  TitleContainer,
} from "./style";

const FAQ = ({ variant }) => {
  const [accordionElements, setAccordionElements] = useState(FAQs);

  const toggleAccordion = (idx) => {
    setAccordionElements((prevState) =>
      prevState.map((data, index) =>
        index === idx
          ? {
              ...data,
              open: !data.open,
            }
          : {
              ...data,
              open: false,
            }
      )
    );
  };

  return (
    <FAQContainer>
      <TitleContainer>
        {t("FAQ")
          .split("BREAK")
          .map((data) => (
            <>
              <Title variant={variant}>{data}</Title>
              <br />
            </>
          ))}
      </TitleContainer>
      <TextContainer>
        <AccordionContainer>
          {accordionElements?.map((data, idx) => (
            <>
              <AccordionElement
                $lastChild={idx === accordionElements.length - 1}
                variant={variant}
                key={data.title}
                onClick={() => data.subText && toggleAccordion(idx)}
              >
                <AccordionTitle>{t(data.title)}</AccordionTitle>
                <span>{data.open ? <Remove /> : <Add />}</span>
              </AccordionElement>
              {data.subText && (
                <AccordionSubText
                  variant={variant}
                  className={data.open && "accordion-open"}
                >
                  {t(data.subText)}
                </AccordionSubText>
              )}
            </>
          ))}
        </AccordionContainer>
      </TextContainer>
    </FAQContainer>
  );
};

export default FAQ;
