import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const FooterContainer = styled(Box)`
  width: 80%;
  margin: 0 auto;
  margin-top: 8vw;
  padding: 3vw 0;
  display: grid;
  grid-template-columns: 0.5fr 2fr;
  gap: 10vw;
  border-top: 1px solid ${({ theme, variant }) => theme?.[variant]?.textColor};
  align-items: flex-start;
`;

export const LogoContainer = styled(Box)`
  width: 14vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 2.5vw;
    font-weight: 400;
    font-family: "Classic", sans-serif;
    text-transform: uppercase;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    line-height: 1;
    margin-bottom: 1vw;
  }
`;

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  align-items: flex-start;
`;

export const GridElement = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 1vw;
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
`;

export const GridTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.8vw;
    font-weight: 400;
    font-family: "Classic", sans-serif;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 1vw;
  }
`;

export const GridLink = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.2vw;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    line-height: 1;
    cursor: pointer;
  }
`;

export const SocialMediaContainer = styled(Box)`
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  & > * {
    cursor: pointer;
  }
`;
