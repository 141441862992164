import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const SwitcherContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  padding: 2vw;
`;

export const ImgContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled(Box)`
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: black;
    font-size: ${({ textSize }) => textSize || "3vw"};
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
  }
`;

export const ModesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3vw;
`;

export const ModeBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ModeImgContainer = styled(Box)`
  width: 100%;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 8vw;
    height: 4vw;
    & path {
      stroke: ${({ variant, theme, selected }) =>
        selected ? theme?.[variant]?.textColor : "#777777"};
    }
  }
`;

export const ModeText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const ModeSelected = styled(Box)`
  width: 100%;
  margin-top: 0.5vw;
  border: ${({ selected, variant, theme }) =>
    selected ? `2px solid ${theme[variant].textColor}` : "none"};
`;

export const SignupContainer = styled(Box)`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
`;

export const SignupBanner = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    font-size: 1.2vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    padding: 1.2vw 2vw;
    border-radius: 0.8vw;
    text-align: center;
    cursor: pointer;
  }
`;

export const SignupText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 0.8vw;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
`;
