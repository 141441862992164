import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Completed } from "../../../assets/Completed.svg";
import { ReactComponent as DisabledCheck } from "../../../assets/DisabledCheck.svg";
import BackgroundUrl from "../../../assets/GenericBackground.svg";
import { setFromReward, setSelectedEvent } from "../../../store/userSlice/userSlice";
import { ACTIVITY_DICTIONARY } from "../../../utils/utils";
import Find from "../../Find/Find";
import Bonus from "./Bonus";
import {
  AchievementsContainer,
  BonusElement,
  BonusElementsContainer,
  Bonuses,
  BonusLabel,
  BonusSubText,
  BonusSymbolContainer,
  BonusTextContainer,
  BorderBottom,
  ImageContainer,
  Title,
  WinsContainer,
} from "./style";

export const Wins = ({ variant, onClickSwitchHandler }) => {
  const { activities, id, accessToken, expiresAt } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isLoggedIn = !!id && accessToken && new Date(expiresAt) > new Date();

  const onTooltipLocateHandler = (id) => {
    dispatch(setSelectedEvent(id));
    dispatch(setFromReward(true));
    onClickSwitchHandler("AR Experience");
  }

  return (
    <ImageContainer $backgroundUrl={BackgroundUrl}>
      <WinsContainer>
        <Title variant={variant}>{t(`My Status`)}</Title>
        <AchievementsContainer>
          <Bonus variant={variant} />
          <Bonuses>
            {Array.from({ length: 2 }, (_, i) => (
              <BonusElementsContainer key={i}>
                {activities
                  ?.slice(
                    i * (activities.length / 2),
                    (i + 1) * (activities.length / 2)
                  )
                  .map((achievement, idx) => (
                    <>
                      <BonusElement variant={variant} key={idx}>
                        <BonusTextContainer>
                          <BonusLabel variant={variant}>
                            {
                              ACTIVITY_DICTIONARY.find(
                                (data) => data.id === achievement.id
                              )?.label
                            }
                          </BonusLabel>
                          <BonusSubText variant={variant}>
                            +{achievement.points}p
                          </BonusSubText>
                        </BonusTextContainer>
                        <BonusSymbolContainer>
                          {
                            isLoggedIn ? (
                              <>
                                <Find onClickHandler={onTooltipLocateHandler} id={achievement?.id} active={false} />
                                <DisabledCheck />
                              </>
                            ) : (
                              <>
                                <Find active={achievement?.rewardedAt} onClickHandler={onTooltipLocateHandler} id={achievement?.id} />
                                {
                                  achievement?.rewardedAt ? <Completed /> : <DisabledCheck />
                                }
                              </>
                            )
                          }
                        </BonusSymbolContainer>
                      </BonusElement>
                      {idx < activities?.length - 1 && (
                        <BorderBottom variant={variant} />
                      )}
                    </>
                  ))}
              </BonusElementsContainer>
            ))}
          </Bonuses>
        </AchievementsContainer>
      </WinsContainer>
    </ImageContainer>
  );
};
