import { Box } from "@mui/material";
import styled from "styled-components";

export const ModesContainer = styled(Box)`
  height: 10svh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const ModeBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ModeImgContainer = styled(Box)`
  width: 100%;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 10vw;
    height: 10vw;
    & path {
      stroke: ${({ variant, theme, selected }) =>
    selected ? theme?.[variant]?.textColor : "#777777"};
    }
  }
`;

export const ModeSelected = styled(Box)`
  width: 100%;
  margin-top: 0.5vw;
  border: ${({ selected, variant, theme }) =>
    selected ? `2px solid ${theme[variant].textColor}` : "none"};
`;
