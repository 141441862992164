import { Box } from "@mui/material";
import styled from "styled-components";

export const VideoContainer = styled(Box)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ImgContainer = styled(Box)`
  margin-top: 15vw;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const VideoPlay = styled(Box)`
  width: 30vw;
  position: absolute;
  top: 20vw;
  left: 35vw;
`;
