import { Info } from "@mui/icons-material";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackgroundUrl from "../../../assets/GenericBackground.svg";
import { login, register } from "../../../store/authSlice/authAsyncThunk";
import {
  getActivities,
  getUser,
} from "../../../store/userSlice/userAsyncThunk";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  CancelText,
  DisclaimerContainer,
  EmailInput,
  ImageContainer,
  LoginContainer,
  SignUp,
  SubmitButton,
  Text,
  TextContainer,
} from "./style";

export const Login = ({ variant, onClickSwitchHandler }) => {
  const { newUser, loginSuccess } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.user);
  const [emailValue, setEmailValue] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [enterClicked, setEnterClicked] = useState(false);
  const [emailCorrect, setEmailCorrect] = useState(false);

  const dispatch = useDispatch();

  const goToMapHandler = () => {
    onClickSwitchHandler("AR Experience");
  };

  /* eslint-disable */
  useEffect(() => {
    if (emailCorrect) {
      dispatch(login(emailValue));
    }
  }, [emailCorrect]);

  useEffect(() => {
    if (newUser) {
      dispatch(register(emailValue)).then(() => dispatch(login(emailValue)));
    }
  }, [newUser]);

  useEffect(() => {
    if (loginSuccess) {
      dispatch(getUser()).then(() => {
        dispatch(getActivities());
      });
    }
  }, [loginSuccess]);

  return (
    <ImageContainer $backgroundUrl={BackgroundUrl}>
      <LoginContainer>
        {!emailCorrect ? (
          <>
            <TextContainer>
              <DisclaimerContainer>
                <Text variant={variant}>{t("emailConnection")}</Text>
                <Tooltip
                  disableHoverListener
                  open={tooltipOpen}
                  title={t("disclaimer")}
                >
                  <Info
                    sx={{
                      color: "white",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setTooltipOpen(!tooltipOpen)}
                  />
                </Tooltip>
              </DisclaimerContainer>
              <EmailInput
                onChange={(e) => setEmailValue(e.target.value)}
                value={emailValue}
                placeholder="Email"
                $appVariant={variant}
              />
              {enterClicked ? (
                <SubmitButton
                  onClick={() => setEmailCorrect(true)}
                  variant={variant}
                >
                  {t("itsCorrect")}
                </SubmitButton>
              ) : (
                <SubmitButton
                  onClick={() => setEnterClicked(true)}
                  variant={variant}
                >
                  {t("enter")}
                </SubmitButton>
              )}
              <CancelText variant={variant} onClick={() => goToMapHandler()}>{t("cancel")}</CancelText>
            </TextContainer>
            <SignUp
              onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
              variant={variant}
            >
              {t("signUp")}
            </SignUp>
          </>
        ) : (
          <>
            <TextContainer
              style={{
                marginBottom: "45vw",
              }}
            >
              <Text variant={variant}>{t("alreadyLoggedSubText")}</Text>
              <SubmitButton onClick={() => goToMapHandler()} variant={variant}>
                {t("goToTheMap")}
              </SubmitButton>
            </TextContainer>
          </>
        )}
      </LoginContainer>
      <Backdrop open={loading}>
        <CircularProgress
          sx={{ color: "white", margin: "0 auto" }}
          size="5rem"
        />
      </Backdrop>
    </ImageContainer>
  );
};
