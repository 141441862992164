import { Box } from "@mui/material";
import { CameraControls, Environment, Float } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import {
  Bloom,
  EffectComposer,
  N8AO,
  Noise,
} from "@react-three/postprocessing";
import CamCtrl from "camera-controls";
import PropTypes from "prop-types";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box3, Vector3 } from "three";
import { postEvent } from "../../store/userSlice/userAsyncThunk.js";
import { setFromReward, setQrModal } from "../../store/userSlice/userSlice.js";
import { latLonToLocalCoords } from "../../utils/utils.jsx";
import { GPSModal as GPSDesktopModal } from "../DesktopComponents/GPSModal/GPSModal.jsx";
import { PlaceholderMap as DesktopPlaceholderMap } from "../DesktopComponents/PlaceholderMap/PlaceholderMap.jsx";
import { GPSModal as GPSMobileModal } from "../MobileComponents/GPSModal/GPSModal.jsx";
import { PlaceholderMap as MobilePlaceholderMap } from "../MobileComponents/PlaceholderMap/PlaceholderMap.jsx";
import CenterUserButton from "./CenterUserButton.jsx";
import Map from "./Map.jsx";
import Pin from "./Pin.jsx";
import Tooltip from "./Tooltip.jsx";
import User from "./User.jsx";


const InteractiveMap = ({ started, locations, selectedEvent, variant }) => {
  const [mapLocation, setMapLocation] = useState([]);
  const [activeFollow, setActiveFollow] = useState(true);

  const { activities, fromReward } = useSelector((state) => state.user);

  // const mockGPS = () => {
  //   const time = Date.now();
  //   const latitude = 48.86;
  //   const longitude = 2.33 + Math.cos(time / 10) / 100;

  //   return { latitude, longitude };
  // };

  const dispatch = useDispatch();

  const userRef = useRef();
  const cameraRef = useRef();
  const followingUser = useRef(true);

  const onSetCameraRef = useCallback(
    (cameraControls) => {
      if (cameraControls) {
        const bb = new Box3(new Vector3(-28, 0, -15), new Vector3(28, 1, 15));
        cameraControls.setBoundary(bb);
      }
      cameraRef.current = cameraControls;
      if (mapLocation.length > 0 && selectedEvent && fromReward) {
        moveCameraTo(
          {
            x:
              mapLocation.find((data) => data.id === selectedEvent)?.pos3D.x +
              2,
            y: 4,
            z:
              mapLocation.find((data) => data.id === selectedEvent)?.pos3D.z +
              3.5,
          },
          mapLocation.find((data) => data.id === selectedEvent)?.pos3D
        );
      }
    },
    [selectedEvent, mapLocation, fromReward]
  );

  const moveCameraTo = async (pos, target) => {
    followingUser.current = false;

    const { x, y, z } = pos;
    const { x: tX, y: tY, z: tZ } = target;
    await cameraRef.current?.setLookAt(x, y, z, tX, tY, tZ, true);
  };

  const onActionClicked = (action, locationId) => {
    const { lat, lon } = activities.find((data) => data.id === locationId);
    switch (action) {
      case "ar":
        dispatch(postEvent({
          eventType: "experienceStarted",
          userAgent: navigator.userAgent,
          language: navigator.language,
        }))
        window.open(locations.find((data) => data.id === locationId)?.link);
        break;
      case "navigate":
        dispatch(postEvent({
          eventType: "navigateStarted",
          userAgent: navigator.userAgent,
          language: navigator.language,
        }))
        window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`,
          "_blank"
        );
        break;
      case "qr":
        dispatch(setQrModal(locationId));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const parsedLocations = locations.map((location) => {
      return {
        id: location?.id,
        name: location.name,
        posGPS: { x: location.lat || 0, y: location.long || 0 },
        pos3D: latLonToLocalCoords(location.lat, location.long),
        address: location.address,
        points: 500,
      };
    });

    setMapLocation(parsedLocations);
  }, [locations]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const updatePosition = () => {
      if (!userRef?.current) return;

      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        // const { latitude, longitude } = mockGPS();
        const coords = latLonToLocalCoords(latitude, longitude);

        if (coords != null) {
          userRef.current.visible = true;
          userRef.current.position.x = coords.x;
          userRef.current.position.z = coords.z;

          if (followingUser.current) {
            cameraRef.current.setLookAt(
              coords.x + 2,
              4,
              coords.z + 3.5,
              coords.x,
              0,
              coords.z,
              true
            );
          }
        } else {
          if (userRef.current) {
            userRef.current.visible = false;
          }
        }
      });
    };

    const intervalId = setInterval(updatePosition, 1000);
    updatePosition();

    return () => clearInterval(intervalId);
  }, []);

  const [isMobile, setIsMobile] = useState(window.screen.width < 768);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.screen.width < 768);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setIsMobile(window.screen.width < 768);
      });
    };
  }, []);

  const onFollowingUserHandler = () => {
    followingUser.current = true;
    setActiveFollow(true);
  }

  useEffect(() => {
    followingUser.current = true;
    setActiveFollow(true);
  }, [])

  useEffect(() => {
    if (selectedEvent) {
      dispatch(
        postEvent({
          eventType: "eventVisualized",
          userAgent: navigator.userAgent,
          language: navigator.language,
        })
      )
    }
  }, [selectedEvent])

  return started ? (
    <Box id="mainContainer" style={{
      position: "relative",
    }} height={isMobile ? "83.5svh" : "50svh"}>
      <Canvas
        flat
        shadows={false}
        dpr={[1, 1.5]}
        gl={{ antialias: false, logarithmicDepthBuffer: true }}
        style={{
          position: "relative",
          zIndex: 10,
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        camera={{ fov: 50, near: 0.01, far: 100, position: [-3, 5, 6] }}
      >
        <CameraControls
          ref={onSetCameraRef}
          verticalDragToForward
          minPolarAngle={Math.PI / 4}
          maxPolarAngle={Math.PI / 3.5}
          minDistance={2.5}
          maxDistance={8}
          touches={{
            two: CamCtrl.ACTION.TOUCH_DOLLY_ROTATE,
            one: CamCtrl.ACTION.TOUCH_TRUCK,
          }}
          onStart={() => {
            followingUser.current = false;
            setActiveFollow(false);
            dispatch(setFromReward(false));
          }}
        />
        <Suspense>
          <Float
            speed={1.0}
            rotationIntensity={0.1}
            floatIntensity={0.01}
            floatingRange={[0.25, 0.5]}
          >
            <Map />
            {mapLocation.map((loc) => (
              <Fragment key={loc.id}>
                <Pin locationId={loc.id} position={loc.pos3D} visible={true} />
                <Tooltip
                  locationId={loc.id}
                  position={loc.pos3D}
                  title={loc.name}
                  address={loc.address}
                  points={loc.points}
                  visible={selectedEvent === loc.id ?? false}
                  onActionClicked={onActionClicked}
                />
              </Fragment>
            ))}
            <User ref={userRef} />
          </Float>
        </Suspense>
        <color args={[0.005, 0.005, 0.005]} attach="background" />
        <directionalLight position={[5, 10, 10]} />
        <Environment files={"./map_assets/env.hdr"} />
        <EffectComposer>
          <Noise opacity={0.05} />
          <N8AO
            aoRadius={0.6}
            intensity={4}
            distanceFalloff={0.25}
            quality="ultra"
            color={"#000000"}
            depthAwareUpsampling={true}
            aoSamples={32}
          />
          <Bloom
            luminanceThreshold={1.0}
            luminanceSmoothing={0.03}
            intensity={0.5}
            radius={0.5}
            mipmapBlur
          />
        </EffectComposer>
      </Canvas>
      <CenterUserButton
        active={activeFollow}
        onClickHandler={onFollowingUserHandler}
      />
      {
        isMobile ? <GPSMobileModal variant={variant} /> : <GPSDesktopModal variant={variant} />
      }
    </Box>
  ) : isMobile ? (
    <MobilePlaceholderMap variant={variant} />
  ) : (
    <DesktopPlaceholderMap variant={variant} />
  );
};

InteractiveMap.propTypes = {
  locations: PropTypes.array.isRequired,
  onPinClicked: PropTypes.func,
  onActionClicked: PropTypes.func,
};

export default InteractiveMap;
