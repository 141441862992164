import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const LogoContainer = styled(Box)`
  width: 100%;
  padding: 20px;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const LanguageSelectorContainer = styled(Box)`
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const LanguageElement = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1vw;
  padding: 10px;
  border: ${({ selected }) => (selected ? "1px solid #707070" : "")};
`;

export const LanguageText = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    font-size: 0.8vw;
  }
`;

export const LanguageFlag = styled(Box)`
  width: 100%;
  & svg {
    width: 0.8vw;
    height: 0.8vw;
  }
`;
