const PlayButton = () => (
  <svg fill="none" viewBox="0 0 33 33" height={36}>
    <path
      stroke="#52FF00"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M31.584 16.5c0 8.333-6.759 15.092-15.092 15.092C8.16 31.592 1.4 24.832 1.4 16.5c0-8.333 6.768-15.092 15.1-15.092 8.333 0 15.092 6.76 15.092 15.092h-.008Z"
    />
    <path
      stroke="#52FF00"
      strokeWidth="2"
      d="m21.975 15.82-9.231 5.33V10.491l9.23 5.33Z"
    />
  </svg>
);

export default PlayButton;
