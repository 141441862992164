import { t } from "i18next";
import React from "react";
import {
  FooterContainer,
  GridContainer,
  GridElement,
  GridLink,
  GridTitle,
  LogoContainer,
  SocialMediaContainer,
  Title,
  TitleContainer,
} from "./style";
import { gridMenus } from "../../../utils/utils";
import { useSelector } from "react-redux";

const Footer = ({ variant }) => {
  const { language } = useSelector((state) => state.user);
  return (
    <FooterContainer variant={variant}>
      <LogoContainer>
        <img
          src={require(`../../../assets/Desktop/TheHeartLogo.png`)}
          alt="The Heart Logo"
        />
        <TitleContainer>
          <Title variant={variant}>EXPERIENCE</Title>
        </TitleContainer>
      </LogoContainer>
      <GridContainer>
        {gridMenus(language).map((menu, index) => (
          <GridElement variant={variant} key={index}>
            <GridTitle>{menu.title}</GridTitle>
            {menu.items.map((item, index) => (
              <GridLink
                onClick={() => {
                  window.location.href = item.link;
                }}
                key={index}
              >
                {t(item.label)}{" "}
                <span style={{ marginLeft: ".3vw" }}>
                  <img
                    src={require("../../../assets/Desktop/GoToLink.png")}
                    alt=""
                    srcset=""
                  />
                </span>
              </GridLink>
            ))}
          </GridElement>
        ))}
        <SocialMediaContainer>
          <img
            src={require("../../../assets/Desktop/FacebookLogo.png")}
            alt=""
            onClick={() =>
              window.open("https://www.facebook.com/FootLockerEU", "_blank")
            }
          />
          <img
            src={require("../../../assets/Desktop/InstagramLogo.png")}
            alt=""
            onClick={() =>
              window.open("https://www.instagram.com/footlockereu/", "_blank")
            }
          />
        </SocialMediaContainer>
      </GridContainer>
    </FooterContainer>
  );
};

export default Footer;
