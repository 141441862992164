import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const RewardContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
`;

export const ImageAndTextContainer = styled(Box)`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  margin-top: -50vw;
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 25vw;
    height: 25vw;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    text-align: center;
    font-size: 8vw;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15vw;
  gap: 5vw;
`;

export const Button = styled(Box)`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  font-weight: 800;
  font-family: "Classic", sans-serif;
  color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  text-transform: uppercase;
  border-radius: 2vw;
  cursor: pointer;
  padding: 3vw;
  text-align: center;
`;
