import React from "react";
import {
  ModeBox,
  ModeImgContainer,
  ModesContainer,
  ModeSelected,
} from "./style";

const SwitcherBanner = ({ variant, switcherModes, onClickSwitchHandler }) => {
  return (
    <ModesContainer>
      {switcherModes?.map((data) => (
        <ModeBox
          onClick={() => onClickSwitchHandler(data.label)}
          variant={variant}
        >
          <ModeImgContainer variant={variant} selected={data.selected}>
            {data.icon}
          </ModeImgContainer>
          <ModeSelected selected={data.selected} variant={variant} />
        </ModeBox>
      ))}
    </ModesContainer>
  );
};

export default SwitcherBanner;
