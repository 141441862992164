import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Earn } from "../../../assets/Earn.svg";
import { ReactComponent as Redeem } from "../../../assets/Redeem.svg";
import { ReactComponent as Scan } from "../../../assets/Scan.svg";
import ShareBanner from "../ShareBanner/ShareBanner";
import {
  Card,
  CardContainer,
  CardLink,
  CardText,
  CardTextContainer,
  CardTitle,
  Container,
  IconContainer,
  StripedColumns,
  StripedContainer,
  Title,
  UnderlinedText,
} from "./style";
import { setSignUpModal } from "../../../store/userSlice/userSlice";

const HowItWorks = ({ variant }) => {
  const { id } = useSelector((state) => state.user);
  const { expiresAt } = useSelector((state) => state.auth);
  const isLoggedIn = !!id && new Date(expiresAt) > new Date();
  const dispatch = useDispatch();
  const howItWorksCards = [
    {
      icon: <Scan />,
      title: t("scanAndExperience"),
      text: t("unlock12Experience"),
    },
    {
      icon: <Earn />,
      title: t("earnXPoints")
        .split("BREAK")
        .map((data, idx) =>
          !!idx ? (
            <>
              {data} <br />
            </>
          ) : (
            data
          )
        ),
      text: t("earn46000XPoints"),
      underlinedText: t("mustHaveFLXAccount"),
      link: !isLoggedIn && t("notFlxMember"),
    },
    {
      icon: <Redeem />,
      title: t("redeemAwards"),
      text: t("useXPoints"),
    },
  ];

  return (
    <Container>
      <Title variant={variant}>
        {t(`howItWorks`).split("BREAK").join(" ")}
      </Title>
      <StripedContainer>
        {Array.from({ length: 56 }).map((_, i) => (
          <StripedColumns odd={i % 2 !== 0} key={i} variant={variant} />
        ))}
      </StripedContainer>
      <CardContainer>
        {howItWorksCards.map((data) => (
          <Card key={data.title} variant={variant}>
            <IconContainer>{data.icon}</IconContainer>
            <CardTitle variant={variant}>{data.title}</CardTitle>
            <CardTextContainer>
              <CardText variant={variant}>{data.text}</CardText>
              {data.underlinedText && (
                <UnderlinedText variant={variant}>
                  {data.underlinedText}
                </UnderlinedText>
              )}
            </CardTextContainer>
            {data?.link && (
              <CardLink
                onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
                variant={variant}
              >
                {data?.link}
              </CardLink>
            )}
          </Card>
        ))}
      </CardContainer>
      <ShareBanner variant={variant} />
    </Container>
  );
};

export default HowItWorks;
