import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  AchievementsContainer,
  Container,
  ImgContainer,
  SignUpButton,
  Text,
  TextAndTitleContainer,
  TextContainer,
  Title,
  TooltipContainer,
  TooltipText,
} from "./style";

const FLXExperience = ({ variant }) => {
  const { id } = useSelector((state) => state.user);
  const { expiresAt } = useSelector((state) => state.auth);
  const isLoggedIn = !!id && new Date(expiresAt) > new Date();
  const dispatch = useDispatch();
  return (
    <Container>
      <ImgContainer>
        <img
          style={{ width: "100%" }}
          src={require("../../../assets/Desktop/FLXExperience.png")}
          alt="FLXExperience"
        />
        <TooltipContainer>
          <img
            style={{ width: "100%" }}
            src={require("../../../assets/Tooltip.png")}
            alt="Tooltip"
          />
          <AchievementsContainer>
            <img
              style={{ width: "100%" }}
              src={require("../../../assets/Achievements.png")}
              alt="Achievements"
            />
          </AchievementsContainer>
          <TooltipText variant={variant}>
            {t("earned500Points")
              .split("BREAK")
              .map((data) => (
                <>
                  {data}
                  <br />
                </>
              ))}
          </TooltipText>
        </TooltipContainer>
      </ImgContainer>

      <TextAndTitleContainer>
        <Title variant={variant}>{t("flxYourExperience")}</Title>
        <TextContainer>
          {t(`getMore`)
            .split("BREAK")
            .map((data, idx) => (
              <Text key={idx} variant={variant}>
                {data}
              </Text>
            ))}
        </TextContainer>
        {!isLoggedIn && (
          <SignUpButton
            onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
            variant={variant}
          >
            {t("signUp")}
          </SignUpButton>
        )}
      </TextAndTitleContainer>
    </Container>
  );
};

export default FLXExperience;
