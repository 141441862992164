import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const TextAndTitleContainer = styled(Box)`
  width: ${({ width }) => width || "100%"};
  height: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || "center"};
  gap: ${({ direction }) => (direction === "row" ? "10vw" : "0")};
  margin-top: 4vw;
  margin-bottom: 2vw;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    font-size: ${({ titleSize }) => titleSize || "4vw"};
    font-weight: 800;
    line-height: 0.8;
    letter-spacing: 0px;
    font-family: "Classic", sans-serif;
    font-style: normal;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-size: 0.9vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;
