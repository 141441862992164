import { t } from "i18next";
import React from "react";
import VideoSource from "../../../assets/HeartCGI.mp4";
import DirectionTitleAndText from "../DirectionTitleAndText/DirectionTitleAndText";
import SignupBanner from "../SignupBanner/SignupBanner";
import { ImgContainer, VideoContainer } from "./style";

const Video = ({ variant, onClickEnterFlxEmailHandler }) => {
  return (
    <VideoContainer>
      <DirectionTitleAndText
        variant={variant}
        textDirection={"column"}
        title={["THE", "EXPERIENCE"]}
        text={t("experienceText").split("BREAK")}
        width={"85%"}
        textAlign={"center"}
        alignItems={"center"}
        titleSize={"12vw"}
        textSize={"4vw"}
        textMargin={"5vw"}
        titleMargin={"5vw"}
        titleHeight={"1.1"}
      />
      <ImgContainer>
        <video width="100%" height="310px" controls>
          <source src={VideoSource} type="video/mp4" />
        </video>
      </ImgContainer>
      <SignupBanner
        onClickEnterFlxEmailHandler={onClickEnterFlxEmailHandler}
        variant={variant}
      />
    </VideoContainer>
  );
};

export default Video;
