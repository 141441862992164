import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)`
  width: 95%;
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: .8fr 2fr 1fr;
  align-items: center;
  margin-top: 8vw;
`;

export const IconContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 8vw;
    height: 8vw;
  }
`;

export const TitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
  }
`;

export const SubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5vw;
    text-align: left;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const ShareButton = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    padding: 1.2vw;
    border-radius: 0.5vw;
    cursor: pointer;
  }
`;
