import { Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const ImageContainer = styled(Box)`
  height: 83.5svh;
  width: 100%;
  background-image: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LoginContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled(Box)`
  margin-top: 30vw;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6vw;
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    width: 80%;
    font-size: 3.2vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1.2;
  }
`;

export const EmailInput = styled(TextField)`
  &.MuiFormControl-root {
    width: 90%;
    font-size: 3vw;
    font-weight: 800;
    font-family: "Standard", sans-serif;
    line-height: 1;
    border: 1px solid
      ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
    border-radius: 1vw;
    text-transform: uppercase;
    & .MuiInputBase-input {
      color: ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
      padding: 3vw 5vw;
      text-transform: uppercase;
      text-align: center;
      font-weight: 800;
      font-family: "Standard", sans-serif;
    }
  }
  & fieldset {
    border-width: 0px important;
    border-color: transparent !important;
  }
`;

export const SubmitButton = styled(Box)`
  width: 90%;
  font-size: 4vw;
  font-weight: 400;
  font-family: "Standard", sans-serif;
  line-height: 1;
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
  padding: 4vw 1vw;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  font-family: "Standard", sans-serif;
  border-radius: 1vw;
  text-transform: uppercase;
`;

export const CancelText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 4vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    text-decoration: underline;
  }
`;

export const SignUp = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 30vw;
    font-size: 5vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 5vw;
  }
`;

export const DisclaimerContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
