import { Box } from "@mui/material";

const CenterUserButton = ({ onClickHandler, active }) => {
  return (
    <Box style={{
      width: "95%",
      height: "50px",
      margin: "0 auto",
      position: "relative",
      display: "flex",
      flexDirection: "row",
      zIndex: 10,
      top: "-70px",
      // left: isMobile ? "85vw" : "92.5vw",
    }}>
      <Box style={{ width: "100%" }} />
      <Box
        style={{
          width: "fit-content",
          cursor: "pointer",
          backgroundColor: active ? "red" : "#000",
          padding: "5px",
          borderRadius: "50%"
        }}
        onClick={() => onClickHandler()}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.369 5.5C12.7497 5.5 13.869 4.38071 13.869 3C13.869 1.61929 12.7497 0.5 11.369 0.5C9.98829 0.5 8.869 1.61929 8.869 3C8.869 4.38071 9.98829 5.5 11.369 5.5Z"
            stroke={"#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.029 10.8L16.929 10.432C16.3667 10.245 15.8382 9.96844 15.364 9.613L14.348 8.85C12.6223 7.55682 10.3146 7.34823 8.385 8.311V8.311C6.26202 9.36164 4.90507 11.5115 4.87 13.88C4.83732 14.6017 5.30511 15.2513 6 15.449C6.43622 15.5593 6.89891 15.468 7.26049 15.2002C7.62207 14.9324 7.84434 14.5164 7.866 14.067C7.86472 12.7989 8.55471 11.6309 9.666 11.02L9.866 10.92V12.694C9.86615 14.1127 9.71089 15.5271 9.403 16.912V16.912C8.99047 18.4444 8.23473 19.8628 7.193 21.06C6.67689 21.7081 6.7839 22.6519 7.432 23.168C8.08011 23.6841 9.02389 23.5771 9.54 22.929C10.7598 21.389 11.6727 19.6292 12.229 17.745C13.4655 19.1036 14.3924 20.7143 14.946 22.466C15.11 22.9811 15.5386 23.3682 16.0678 23.479C16.5969 23.5898 17.1448 23.4073 17.5018 23.0013C17.8588 22.5953 17.9696 22.0286 17.792 21.518C16.9407 18.8196 15.3597 16.4091 13.224 14.553C13.0008 14.3664 12.8709 14.091 12.869 13.8V11.492L13.56 12.01C14.2925 12.5598 15.109 12.9877 15.978 13.277L17.154 13.669C17.5743 13.8095 18.0352 13.757 18.4131 13.5255C18.791 13.294 19.0472 12.9073 19.113 12.469C19.2024 11.7238 18.7462 11.0213 18.029 10.8Z"
            stroke={"#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Box>
    </Box>
  );
};

export default CenterUserButton;
