import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ImageContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WinsContainer = styled(Box)`
  width: 70%;
  margin: 0 auto;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    font-size: 4.5vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
    margin-bottom: 2vw;
  }
`;

export const AchievementsContainer = styled(Box)`
  width: 100%;
  margin-top: 3vw;
  display: grid;
  grid-template-columns: 0.8fr 2fr;
  gap: 6vw;
  margin-bottom: 3vw;
`;

export const BonusContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 2vw;
`;

export const BonusTitle = styled(Typography)`
  &.MuiTypography-root {
    position: absolute;
    width: 100%;
    font-size: 1.35vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
    top: -2.2vw;
    left: 1vw;
  }
`;

export const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1vw;
`;

export const BonusBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BonusImageContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 5vw;
    height: 3vw;
    & g {
      & path {
        stroke: ${({ unlocked }) => (unlocked ? "#52FF00" : "#707070")};
      }
    }
  }
`;

export const BonusText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1vw;
    text-align: center;
    font-weight: 400;
    color: ${({ unlocked }) => (unlocked ? "#52FF00" : "#707070")};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const Bonuses = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const BonusElementsContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
`;

export const BonusElement = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
`;

export const BonusTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BonusLabel = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    font-weight: 800;
    font-size: 1.35vw;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const BonusSubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 0.8vw;
    text-align: left;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const BorderBottom = styled(Box)`
  width: 90%;
  margin-top: 0.2vw;
  align-self: flex-start;
  border: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
`;

export const BonusSymbolContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  align-items: center;
  & svg {
    width: 3vw;
    height: 2vw;
  }
`;
