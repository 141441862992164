import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 8vw;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: black;
    font-size: 6vw;
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
  }
`;

export const StripedContainer = styled(Box)`
  width: 100%;
  height: 40vw;
  display: grid;
  grid-template-columns: repeat(56, 1fr);
  margin-top: 3vw;
  position: relative;
`;

export const StripedColumns = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ odd, variant, theme }) =>
    odd ? theme?.[variant]?.backgroundColor : theme?.[variant]?.textColor};
`;

export const CardContainer = styled(Box)`
  width: 93%;
  height: 35vw;
  display: flex;
  flex-direction: row;
  gap: 1.9vw;
  position: absolute;
  z-index: 1;
  top: 11vw;
  left: 1.8vw;
`;

export const Card = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  padding: 1.5vw;
`;

export const IconContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  & svg {
    width: 6.5vw;
    height: 6.5vw;
  }
`;

export const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    margin-top: 3.5vw;
    font-size: 3vw;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const CardTextContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardText = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 4vw;
    font-size: 1.3vw;
    font-weight: 200;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    text-align: left;
  }
`;

export const UnderlinedText = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 0.5vw;
    font-size: 1vw;
    font-weight: 400;
    text-decoration: underline;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    text-align: left;
    cursor: pointer;
  }
`;

export const CardLink = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    text-align: right;
    margin-top: 4vw;
    font-size: 1.2vw;
    font-weight: 400;
    text-decoration: underline;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 0.8;
    cursor: pointer;
  }
`;
