import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  refreshToken,
  tokensRevoke,
  register,
} from "../../store/authSlice/authAsyncThunk";

const initialState = {
  userId: null,
  accessToken: null,
  expiresAt: null,
  newUser: null,
  loginSuccess: null,
  registrationComplete: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setExpiresAt: (state, action) => {
      state.expiresAt = action.payload;
    },
    setUser: (state, action) => {
      state.userId = action.payload.userId;
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state) => {
      state.newUser = false;
      state.registrationComplete = true;
      localStorage.setItem("registrationComplete", true);
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.userId = action.payload.userId;
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
      localStorage.setItem("accessToken", state.accessToken);
      localStorage.setItem("expiresAt", state.expiresAt);
      state.loginSuccess = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.loginSuccess = false;
      state.newUser = true;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.userId = action.payload.userId;
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
      localStorage.setItem("accessToken", state.accessToken);
      localStorage.setItem("expiresAt", state.expiresAt);
    });
    builder.addCase(refreshToken.rejected, (state) => {
      state = initialState;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiresAt");
    });
    builder.addCase(tokensRevoke.fulfilled, (state) => {
      state = initialState;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiresAt");
    });
  },
});

export const { setAccessToken, setUser, setExpiresAt } = authSlice.actions;
export default authSlice.reducer;
