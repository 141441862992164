import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const AboutContainer = styled(Box)`
  width: 85%;
  margin: 0 auto;
  margin-top: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AboutText = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    text-align: center;
    font-size: 3vw;
    font-weight: 800;
    font-family: "Classic", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-transform: uppercase;
  }
`;

export const AboutElementsContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
`;

export const AboutElement = styled(Box)`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  padding: 3.5vw;
`;

export const AboutElementText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.1vw;
    text-align: center;
    font-weight: 400;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const AboutElementIcon = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 4vw;
    height: 4vw;
  }
`;
