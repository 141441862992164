import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  ImageContainer,
  PrizesContainer,
  ShowPrizes,
  Text,
  TextContainer,
  Title,
} from "./style";

const Prizes = ({ variant }) => {
  const dispatch = useDispatch();
  return (
    <PrizesContainer>
      <ImageContainer>
        <img
          style={{ width: "100%" }}
          src={require(`../../../assets/Mobile/Prizes.png`)}
          alt="Prizes"
        />
      </ImageContainer>
      <TextContainer>
        <Title variant={variant}>
          {t(`mobileGreatPrizes`)
            .split("BREAK")
            .map((data) => (
              <>
                {data}
                <br />
              </>
            ))}
        </Title>
        <Text variant={variant}>{t(`greatPrizesText`)}</Text>
        <ShowPrizes
          onClick={() => dispatch(setSignUpModal("account/flx/dashboard"))}
          variant={variant}
        >
          {t(`showPrizes`)}
        </ShowPrizes>
      </TextContainer>
    </PrizesContainer>
  );
};

export default Prizes;
