import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ImageContainer = styled(Box)`
  height: 83.5svh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WinsContainer = styled(Box)`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-url: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    font-size: 10vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
    margin-bottom: 2vw;
  }
`;

export const AchievementsContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 6vw;
  margin-bottom: 2vw;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const BonusContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 2vw;
  padding: 3vw;
  margin-bottom: 3vw;
`;

export const BonusTitle = styled(Typography)`
  &.MuiTypography-root {
    position: absolute;
    width: 100%;
    font-size: 1.35vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
    top: -2.2vw;
    left: 1vw;
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10vw;
`;

export const BonusBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BonusImageContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 15vw;
    height: 15vw;
    & g {
      & path {
        stroke: ${({ unlocked }) => (unlocked ? "#52FF00" : "#707070")};
      }
    }
  }
`;

export const BonusText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3vw;
    text-align: center;
    font-weight: 400;
    color: ${({ unlocked }) => (unlocked ? "#52FF00" : "#707070")};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const Bonuses = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const BonusElementsContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
`;

export const BonusElement = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
`;

export const BonusTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1vw;
`;

export const BonusLabel = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    font-weight: 800;
    font-size: 5vw;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const BonusSubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3.5vw;
    text-align: left;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const BorderBottom = styled(Box)`
  width: 100%;
  margin-top: 0.2vw;
  align-self: flex-start;
  border: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
`;

export const BonusSymbolContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1vw;
  align-items: center;
  & svg {
    width: 8vw;
    height: 8vw;
  }
`;

export const WinButton = styled(Typography)`
  &.MuiTypography-root {
    width: 90%;
    margin-top: 4vw;
    font-size: 4.5vw;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    text-align: center;
    padding: 3.5vw 5vw;
    border-radius: 2vw;
    cursor: pointer;
    text-transform: uppercase;
  }
`;
