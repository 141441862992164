import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const MapContainer = styled(Box)`
  width: 100%;
  height: 85vh;
  background: url(${({ img }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const LogoContainer = styled(Box)`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  position: relative;
  z-index: 100;
`;

export const ImgContainer = styled(Box)`
  width: 55vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled(Box)`
  position: absolute;
  top: 10vh;
  left: 22vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: black;
    font-size: ${({ textSize }) => textSize || "3vw"};
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
  }
`;

export const Container = styled(Box)`
  width: 100%;
  position: absolute;
  top: 26vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CountdownContainer = styled(Box)`
  margin: 0 auto;
  margin-top: 2vw;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CountdownValue = styled(Box)`
  width: 20vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Countdown = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 15.5vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
    line-height: 0.8;
  }
`;

export const CountdownSubtitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 3vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
  }
`;

export const MiddleText = styled.span`
  -webkit-text-fill-color: ${({ variant, theme }) =>
    theme?.[variant]?.strokeColor};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ variant, theme }) =>
    theme?.[variant]?.textColor};
`;

export const StyledColumn = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14.5vw;
    text-align: center;
    font-weight: bold;
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const LanguageSelectorContainer = styled(Box)`
  position: absolute;
  top: 2vw;
  left: 75vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const LanguageElement = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  border: ${({ selected }) => (selected ? "1px solid #707070" : "")};
`;
