import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpModal } from "../../../store/userSlice/userSlice";
import {
  ImgContainer,
  ModeBox,
  ModeImgContainer,
  ModesContainer,
  ModeSelected,
  ModeText,
  SignupBanner,
  SignupContainer,
  SignupText,
  SwitcherContainer,
  Title,
  TitleContainer,
} from "./style";

const SwitcherBanner = ({ variant, switcherModes, onClickSwitchHandler }) => {
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  return (
    <SwitcherContainer>
      <TitleContainer>
        <ImgContainer>
          <img
            style={{ width: "12vw" }}
            alt="The Heart Logo"
            src={require(`../../../assets/Desktop/TheHeartLogo.png`)}
          />
        </ImgContainer>
        <Title variant={variant} textSize={"2.5vw"}>
          EXPERIENCE
        </Title>
      </TitleContainer>
      <ModesContainer>
        {switcherModes?.map((data) => (
          <ModeBox
            onClick={() => onClickSwitchHandler(data.label)}
            variant={variant}
          >
            <ModeImgContainer variant={variant} selected={data.selected}>
              {data.icon}
            </ModeImgContainer>
            <ModeText variant={variant}>{t(data.label)}</ModeText>
            <ModeSelected selected={data.selected} variant={variant} />
          </ModeBox>
        ))}
      </ModesContainer>
      {!id && (
        <SignupContainer>
          <SignupBanner
            onClick={() => onClickSwitchHandler("Profile")}
            variant={variant}
          >
            {t("enterFLXMail")}
          </SignupBanner>
          <SignupText
            onClick={() => dispatch(setSignUpModal("loyalty-registration.html"))}
            variant={variant}
          >
            {t("notFlxMember")}
          </SignupText>
        </SignupContainer>
      )}
    </SwitcherContainer>
  );
};

export default SwitcherBanner;
