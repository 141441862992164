import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)`
  width: 95%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14vw;
  gap: 3vw;
`;

export const IconContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 22vw;
    height: 22vw;
  }
`;

export const TitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 7vw;
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1.2;
    text-transform: uppercase;
  }
`;

export const SubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 4vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;

export const ShareButton = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 8vw;
    font-size: 4.5vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    padding: 3vw;
    border-radius: 2vw;
    cursor: pointer;
  }
`;
