import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackgroundUrl from "../../../assets/GenericBackground.svg";
import { refreshToken, tokensRevoke } from "../../../store/authSlice/authAsyncThunk";
import { getUser, updateMail } from "../../../store/userSlice/userAsyncThunk";
import {
  ButtonsContainer,
  ImageContainer,
  LogoutButton,
  NormalText,
  ProfileButton,
  ProfileContainer,
  Title,
  UpdateEmail
} from "./style";


export const Profile = ({ variant, onClickSwitchHandler }) => {
  const { mailAddress } = useSelector((state) => state.user);
  const [email, setEmail] = useState(mailAddress);

  const dispatch = useDispatch();

  const onChangeEmail = () => {
    dispatch(updateMail(email)).then(() => {
      dispatch(refreshToken()).then(() => {
        dispatch(getUser()).then(() => onClickSwitchHandler("AR Experience"));
      });
    });
  };

  const onLogoutClickHandler = () => {
    dispatch(tokensRevoke()).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  }

  return (
    <ImageContainer $backgroundUrl={BackgroundUrl}>
      <ProfileContainer>
        <Title variant={variant}>{t("Profile")}</Title>
        <ButtonsContainer>
          <ProfileButton
            $appVariant={variant}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <UpdateEmail onClick={onChangeEmail} variant={variant}>
            {t("updateEmail")}
          </UpdateEmail>
        </ButtonsContainer>
        <LogoutButton
          $appVariant={variant}
          onClick={onLogoutClickHandler}
        >
          {t("logout")}
        </LogoutButton>
        <NormalText variant={variant}>{t("pointsTransferred")}</NormalText>
      </ProfileContainer>
    </ImageContainer >
  );
};
