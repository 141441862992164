import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const PointsBarContainer = styled(Box)`
  width: 100%;
  height: 3vw;
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  opacity: 0.85;
  align-items: center;
  position: relative;
`;

export const PointsBarValueContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => align || "flex-start"};
  align-items: center;
`;

export const PointsBarValue = styled(Box)`
  width: ${({ width }) => width};
  background: linear-gradient(
    90deg,
    rgba(84, 252, 1, 1) 0%,
    rgba(251, 72, 65, 1) 88%
  );
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: ${({ rounded }) =>
    `0 ${rounded && 1.3}vw ${rounded && 1.3}vw 0`};
  z-index: 2;
`;

export const PointsBarText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1vw;
    font-weight: 400;
    color: ${({ color, variant, theme }) =>
      color || theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    padding: 1vw;
  }
`;
