import React, { useEffect, useState } from "react";
import {
  Container,
  Countdown,
  CountdownContainer,
  CountdownSubtitle,
  CountdownValue,
  ImgContainer,
  MapContainer,
  MapImage,
  StyledColumn,
  Title,
  TitleContainer,
} from "./style";

export const PlaceholderMap = ({ variant }) => {
  const map = require(`../../../assets/Map.jpeg`);
  const deadLine = new Date(2024, 6, 26, 0, 0, 0, 0);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = deadLine - now;
      const days =
        Math.floor(distance / (1000 * 60 * 60 * 24)) < 10
          ? `0${Math.floor(distance / (1000 * 60 * 60 * 24))}`
          : Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours =
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) < 10
          ? `0${Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )}`
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes =
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) < 10
          ? `0${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds =
        Math.floor((distance % (1000 * 60)) / 1000) < 10
          ? `0${Math.floor((distance % (1000 * 60)) / 1000)}`
          : Math.floor((distance % (1000 * 60)) / 1000);
      setCountdown({ days, hours, minutes, seconds });
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <>
      <MapContainer>
        <MapImage src={map} alt="Map of Paris" />
        <TitleContainer>
          <ImgContainer>
            <img
              style={{ width: "15vw" }}
              alt="The Heart Logo"
              src={require(`../../../assets/Desktop/TheHeartLogo.png`)}
            />
          </ImgContainer>
          <Title variant={variant} textSize={"2.7vw"}>
            EXPERIENCE
          </Title>
        </TitleContainer>
        <Container>
          <Title variant={variant}>JOIN IN:</Title>
          <CountdownContainer>
            {Object.keys(countdown).map((key, idx) => (
              <>
                <CountdownValue variant={variant} key={countdown[key].label}>
                  <Countdown variant={variant}>{countdown[key]}</Countdown>
                  <CountdownSubtitle variant={variant}>
                    {key.toLocaleUpperCase()}
                  </CountdownSubtitle>
                </CountdownValue>
                {idx < Object.keys(countdown).length - 1 && (
                  <StyledColumn key={idx} variant={variant}>
                    :
                  </StyledColumn>
                )}
              </>
            ))}
          </CountdownContainer>
        </Container>
      </MapContainer>
    </>
  );
};
