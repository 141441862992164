import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import NewReward from "./components/MobileComponents/Reward/NewReward.jsx";
import "./i18n.js";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store.js";
import { theme } from "./utils/utils";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    ),
  },
  {
    path: "/reward",
    element: (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <NewReward />
        </ThemeProvider>
      </Provider>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
