import styled from "styled-components";
import { Box, TextField, Typography } from "@mui/material";

export const ImageContainer = styled(Box)`
  height: 83.5svh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ProfileContainer = styled(Box)`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  margin-top: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-url: ${({ $backgroundUrl }) => `url(${$backgroundUrl})`};
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    font-size: 10vw;
    text-align: left;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 0.8;
    text-transform: uppercase;
    margin-bottom: 2vw;
  }
`;

export const ButtonsContainer = styled(Box)`
  width: 90%;
  margin: 0 auto;
  margin-top: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
`;

export const ProfileButton = styled(TextField)`
  &.MuiFormControl-root {
    width: 92%;
    font-size: 3vw;
    font-weight: 800;
    font-family: "Standard", sans-serif;
    line-height: 1;
    border: 1px solid
      ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
    border-radius: 2.5vw;
    text-transform: uppercase;
    & .MuiInputBase-input {
      color: ${({ $appVariant, theme }) => theme?.[$appVariant]?.textColor};
      text-transform: uppercase;
      text-align: center;
      font-weight: 800;
      font-family: "Standard", sans-serif;
      padding: 4vw;
    }
  }
  & fieldset {
    border-width: 0px important;
    border-color: transparent !important;
  }
`;

export const LanguageButtonContainer = styled(Box)`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  border: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
  border-radius: 0.5vw;
  cursor: pointer;
  padding: 0.3vw;
`;

export const LanguageButton = styled(Box)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: ${({ selected, variant, theme }) =>
    selected ? theme?.[variant]?.textColor : theme?.[variant]?.backgroundColor};
`;

export const LanguageText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.2vw;
    text-align: center;
    font-weight: 800;
    color: ${({ selected, variant, theme }) =>
    selected
      ? theme?.[variant]?.backgroundColor
      : theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
  }
`;

export const UpdateEmail = styled(Typography)`
  &.MuiTypography-root {
    width: 80%;
    font-size: 4.5vw;
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    padding: 4.5vw 5vw;
    border-radius: 2vw;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

export const NormalText = styled(Typography)`
  &.MuiTypography-root {
    width: 90%;
    margin-top: 10vw;
    font-size: 3vw;
    text-align: center;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
    margin-bottom: 30vw;
  }
`;

export const LogoutButton = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 5vw;
    width: 70%;
    font-size: 4.5vw;
    text-align: center;
    font-weight: 800;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: red;
    font-family: "Classic", sans-serif;
    line-height: 1;
    padding: 4.5vw 5vw;
    border-radius: 2vw;
    cursor: pointer;
    text-transform: uppercase;
  }
`;
