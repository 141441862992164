import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ModalContainer = styled(Box)`
  width: 309px;
  height: max-content;
  padding: 30px 20px 20px 20px;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 6vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.95);
  z-index: 100;
`;

export const ModalTitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme, variant }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
  }
`;

export const BulletsPointContainer = styled(Box)`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const BulletPoint = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
`;

export const BulletPointTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: .5vw;
`;

export const BulletPointNumber = styled(Typography)`
  &.MuiTypography-root {
  width: min-content;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme, variant }) => theme?.[variant]?.textColor};
  font-family: "Standard", sans-serif;
  line-height: 1;
}
`

export const BulletPointText = styled(Typography)`
&.MuiTypography-root {
  width: 100%;
  text-align: left;
  font-size: 4.5vw;
  font-weight: 400;
  color: ${({ theme, variant }) => theme?.[variant]?.textColor};
  font-family: "Standard", sans-serif;
  line-height: 1;
}
`;

export const BulletPointIconContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  & svg {
    width: 12vw;
    height: 12vw;
  }
`

export const LetsGoButton = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 5vw;
    width: 80%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme, variant }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ theme, variant }) => theme?.[variant]?.textColor};
    font-family: "Classic", sans-serif;
    line-height: 1;
    padding: 2.5vw;
    border-radius: 2vw;
    cursor: pointer;
  }
`;

export const DontShowItAgainContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5vw;
`;

export const DontShowItAgainText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 13px;
    font-weight: 400;
    color: ${({ theme, variant }) => theme?.[variant]?.textColor};
    font-family: "Standard", sans-serif;
    line-height: 1;
  }
`;
