import { Edges, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { forwardRef, useRef } from "react";

const User = forwardRef((_, ref) => {
	const { nodes } = useGLTF("./map_assets/pin.glb");
	const pinRef = useRef();

	useFrame((_, delta) => {
		if (pinRef.current) {
			pinRef.current.rotation.y += delta * 3;
		}
	});

	return (
		<group ref={ref} scale={40}>
			<mesh ref={pinRef} geometry={nodes.pin_fl_geo.geometry} position={[0, 0.01, 0]}>
				<meshStandardMaterial color="red" emissive={"red"} emissiveIntensity={1} />
				<Edges color="white" lineWidth={2} />
			</mesh>
		</group>
	);
});

User.displayName = "User";

export default User;
