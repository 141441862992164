import React from 'react'

const Find = ({ onClickHandler, id, active = false }) => {
    return (
        <svg onClick={() => onClickHandler(id)} width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.7121 10.6264C26.7121 6.56312 23.2726 3.26538 19.0242 3.26538C14.7758 3.26538 11.3364 6.55859 11.3364 10.6264C11.3364 11.8223 11.6439 12.9502 12.1738 13.9513H12.1691L19.029 25.7969L25.8889 13.9513H25.8841C26.414 12.9502 26.7215 11.8268 26.7215 10.6264H26.7121Z" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.78997 9.44409C5.29499 10.2006 4.27783 11.709 4.27783 13.4394C4.27783 14.1733 4.46707 14.8663 4.78878 15.4824L8.99933 22.7574L10.9674 19.3554" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.3364 25.7969H26.7121" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.36133 22.7573H12.6659" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.2585 9.44409C32.7535 10.2006 33.7706 11.709 33.7706 13.4394C33.7706 14.1733 33.5814 14.8663 33.2597 15.4824L29.0491 22.7574L27.0811 19.3554" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.6872 22.7573H25.3826" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.8772 10.3502C21.8772 11.8586 20.5998 13.0817 19.0244 13.0817C17.449 13.0817 16.1716 11.8586 16.1716 10.3502C16.1716 8.84171 17.449 7.61865 19.0244 7.61865C20.5998 7.61865 21.8772 8.84171 21.8772 10.3502Z" stroke={active ? "#52FF00" : "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Find