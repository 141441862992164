import styled from "styled-components";

export const CenterOnUserButton = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  z-index: 1000;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 1);
  &.hidden {
    display: none;
  }
`;
export const Wrapper = styled.div`
  overflow: visible;
  width: 600px;
  height: 1px;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 130px;
  font-family: "FootLocker-FLStandard", sans-serif;
  display: flex;
  gap: 10px;
  width: fit-content;
  padding: 20px 25px;
  border-radius: 8px;
  font-size: 12px;
  background: #ffffff;
  color: #303030;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    bottom: -14px;
    left: 25%;
    border: 15px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
  }
`;
export const TooltipLeft = styled.div`
  flex: 1 0 auto;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  display: flex;
  width: fit-content;
  flex: 1 0 auto;
  font-family: "FootLocker-FLClassic", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
`;
export const Details = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
export const Separator = styled.div`
  width: 2px;
  height: 64px;
`;
export const TooltipRight = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  gap: 10px;
  flex-direction: column;
  padding-left: 15px;
  cursor: pointer;
`;
