import { t } from "i18next";
import { IoHelpCircleOutline } from "react-icons/io5";
import { LiaShippingFastSolid } from "react-icons/lia";
import { PiPackage } from "react-icons/pi";
import { ReactComponent as FourAR } from "../assets/4AR.svg";
import { ReactComponent as EightAR } from "../assets/8AR.svg";
import { ReactComponent as AchievementsIcon } from "../assets/AchievementsIcon.svg";
import { ReactComponent as AllAR } from "../assets/AllAR.svg";
import { ReactComponent as Check } from "../assets/Check.svg";
import { ReactComponent as FootLockerMan } from "../assets/FootLockerMan.svg";
import { ReactComponent as MapsIcon } from "../assets/MapsIcon.svg";
import { ReactComponent as NavigateTo } from "../assets/NavigateTo.svg";
import { ReactComponent as ProfileIcon } from "../assets/ProfileIcon.svg";
import { Login as LoginDesktop } from "../components/DesktopComponents/Login/Login";
import { Profile as ProfileDesktop } from "../components/DesktopComponents/Profile/Profile";
import { Wins as WinsDesktop } from "../components/DesktopComponents/Wins/Wins";
import InteractiveMap from "../components/InteractiveMap/InteractiveMap";
import { Login as LoginMobile } from "../components/MobileComponents/Login/Login";
import { Profile as ProfileMobile } from "../components/MobileComponents/Profile/Profile";
import { Wins as WinsMobile } from "../components/MobileComponents/Wins/Wins";
import { ReactComponent as QRIcon } from "../assets/Experience1.svg";

export const MAX_POINTS = 46000;

export const FooterCTAValues = [
  {
    label: "Help & Contact Us",
    icon: <IoHelpCircleOutline />,
  },
  {
    label: "Track my order",
    icon: <PiPackage />,
  },
  {
    label: "Shipping & Delivery",
    icon: <LiaShippingFastSolid />,
  },
  {
    label: "Returns & Refunds",
    icon: <LiaShippingFastSolid />,
  },
];

export const ExpandingFooterMenus = [
  {
    label: "Legal Information",
    subValues: [],
  },
  {
    label: "About",
    subValues: [],
  },
  {
    label: "Shop",
    subValues: [],
  },
];

export const theme = {
  dark: {
    backgroundColor: "black",
    textColor: "white",
    strokeColor: "black",
  },
  light: {
    backgroundColor: "white",
    textColor: "black",
    strokeColor: "white",
  },
};

export const SwitcherModes = (mobile = false, isLoggedIn = false) => [
  {
    label: "AR Experience",
    icon: <MapsIcon />,
    component: <InteractiveMap />,
    selected: false,
  },
  {
    label: "My Status",
    icon: <AchievementsIcon />,
    component: mobile ? <WinsMobile /> : <WinsDesktop />,
    selected: false,
  },
  {
    label: "Profile",
    icon: <ProfileIcon />,
    component: mobile ? (
      isLoggedIn ? (
        <ProfileMobile />
      ) : (
        <LoginMobile />
      )
    ) : isLoggedIn ? (
      <ProfileDesktop />
    ) : (
      <LoginDesktop />
    ),
    selected: false,
  },
];

export const BonusTypes = [
  {
    label: "4ARsCollected",
    icon: <FourAR />,
    unlocked: true,
    unlockFunction: (achievements) =>
      achievements.filter((data) => data.rewardedAt).length >= 4,
  },
  {
    label: "8ARsCollected",
    icon: <EightAR />,
    unlocked: false,
    unlockFunction: (achievements) =>
      achievements.filter((data) => data.rewardedAt).length >= 8,
  },
  {
    label: "allARsCollected",
    icon: <AllAR />,
    unlocked: false,
    unlockFunction: (achievements) =>
      achievements.filter((data) => data.rewardedAt).length >= 12,
  },
];

export const AvailableLanguages = [
  {
    label: "English",
    key: "en",
    icon: <span className="fi fi-gb" />,
    selected: false,
  },
  {
    label: "Français",
    key: "fr",
    icon: <span className="fi fi-fr" />,
    selected: false,
  },
];

export const gridMenus = (language) => [
  {
    title: "Legal Information",
    items: [
      {
        label: "yourPrivacyRights",
        link: `https://www.footlocker-emea.com/${language}/privacy.html`,
      },
      {
        label: "confidentialityDeclaration",
        link: `
        https://www.footlocker-emea.com/content/footlocker-corp-eu/eu/${language}/privacy/customer-privacy-statement-europe-.html
        `,
      },
      {
        label: "termsAndConditions",
        link: `https://www.footlocker.fr/${language}/terms.html`,
      },
    ],
  },
  {
    title: "About",
    items: [
      {
        label: "aboutFootLocker",
        link: `https://www.footlocker-emea.com/${language}/about.html`,
      },
      {
        label: "pressContact",
        link: `https://www.footlocker.fr/${language}/press-contacts.html`,
      },
      {
        label: "ourStores",
        link: `https://stores.footlocker.fr/${language}/`,
      },
    ],
  },
];

export const ACTIVITY_DICTIONARY = [
  {
    label: "The Dancing Referee",
    id: "ACT0001",
    link: "https://adobeaero.app.link/JHo01dycKKb",
    address: "4-6 Rue Pierre Lescot, 75001 Paris",
  },
  {
    label: "The Sneaker Factory",
    id: "ACT0002",
    link: "https://adobeaero.app.link/uxIWvJ4cKKb",
    address: "La Defense",
  },
  {
    label: "The Striped Portal",
    id: "ACT0003",
    link: "https://adobeaero.app.link/wemGfzwdKKb",
    address: "4-6 Rue Pierre Lescot, 75001 Paris",
  },
  {
    label: "Sneaker Keeper",
    id: "ACT0004",
    link: "https://adobeaero.app.link/y17hXA7wLKb",
    address: "Igor Stravinsky Square",
  },
  {
    label: "Catch Machine",
    id: "ACT0005",
    link: "https://adobeaero.app.link/UVwPV0hFLKb",
    address: "Commercial Center Beaugrenelle",
  },
  {
    label: "Sneaker Balloons",
    id: "ACT0006",
    link: "https://adobeaero.app.link/BtVFtXOwLKb",
    address: "36 Av. des Champs-Élysées, 75008 Paris, France",
  },
  {
    label: "The Deconstructed Sneaker",
    id: "ACT0007",
    link: "https://adobeaero.app.link/QPpVGZcfKKb",
    address: "SAINT-LAZARE",
  },
  {
    label: "Metropolitan",
    id: "ACT0008",
    link: "https://adobeaero.app.link/nzbMg2kfKKb",
    address: "Skate Park Léon Cladel",
  },
  {
    label: "Flying Sneakers",
    id: "ACT0009",
    link: "https://adobeaero.app.link/6V7OefFzLKb",
    address: "66 Rue de Rivoli",
  },
  {
    label: "Robotic Arm",
    id: "ACT0010",
    link: "https://adobeaero.app.link/Mku474hxLKb",
    address: "124 Bd de la Chapelle, 75018 Paris, Francia",
  },
  {
    label: "Next Stop",
    id: "ACT0011",
    link: "https://adobeaero.app.link/5AOHlOzxLKb",
    address: "12 Rue du Faubourg Saint-Antoine Paris, 75012",
  },
  {
    label: "Billboards Facade",
    id: "ACT0012",
    link: "https://adobeaero.app.link/Tk7B157gKKb",
    address: "45 Rue de Rivoli",
  },
];

export const FAQs = Array.from({ length: 22 }, (_, i) => ({
  title: `FAQ${i}`,
  subText: `FAQAnswer${i}`,
  open: false,
}));

const topLeft = { lat: 48.896997, lon: 2.227025, x: -30.4864, y: 16.1616 };
const bottomRight = { lat: 48.843237, lon: 2.380264, x: 31.125, y: -16.8156 };

/**
 * Converts latitude and longitude coordinates to local coordinates on a map.
 *
 * @param {number} lat - The latitude coordinate.
 * @param {number} lon - The longitude coordinate.
 * @return {Object|null} An object with x and y coordinates if the position is within the map boundaries,
 *                        or null if the position is outside the boundaries.
 */
export const latLonToLocalCoords = (lat, lon) => {
  // Coordinate del bordo della mappa

  // Controllo se la posizione è all'interno dei bordi
  if (
    lat < bottomRight.lat ||
    lat > topLeft.lat ||
    lon < topLeft.lon ||
    lon > bottomRight.lon
  ) {
    return null; // Posizione fuori dai bordi
  }

  // Interpolazione delle coordinate
  const x =
    ((lon - topLeft.lon) / (bottomRight.lon - topLeft.lon)) *
    (bottomRight.x - topLeft.x) +
    topLeft.x;
  const z =
    ((lat - bottomRight.lat) / (topLeft.lat - bottomRight.lat)) *
    (topLeft.y - bottomRight.y) +
    bottomRight.y;

  return { x, y: 0, z: z * -1 };
};

export const AvailableFlags = (isMobile) => [
  {
    label: "fr",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-fr"
      />
    ),
  },
  {
    label: "co.uk",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-gb"
      />
    ),
  },
  {
    label: "it",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-it"
      />
    ),
  },
  {
    label: "de",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-de"
      />
    ),
  },
  {
    label: "es",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-es"
      />
    ),
  },
  {
    label: "nl",
    flag: (
      <span
        style={{ fontSize: isMobile ? "18vw" : "6vw" }}
        className="fi fi-nl"
      />
    ),
  },
];

export const bulletPoints = (mobile = true) => {
  return [
    {
      label: t("searchReferee"),
      icons: [<FootLockerMan />]
    },
    {
      label: t("clickTheExperience"),
      icons: []
    },
    {
      label: mobile ? t("nearbyMobile") : t("nearbyDesktop"),
      icons: mobile ? [<Check />, <NavigateTo />] : [
        <QRIcon />
      ]
    },
    {
      label: t("afterComplete"),
      icons: []
    },
  ]
}
