const PathButton = () => (
  <svg fill="none" viewBox="0 0 41 35" height={36}>
    <g
      stroke="#52FF00"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      clipPath="url(#a)"
    >
      <path d="M15.168 27.45h-7.15c-2.957 0-4.386 1.102-4.386 3.423 0 2.32 1.478 3.421 4.386 3.421h24.125" />
      <path d="M15.168 27.451c2.957 0 4.386-1.1 4.386-3.422 0-2.32-1.479-3.421-4.386-3.421H7.264M40.29 17.63c0-4.465-3.647-8.085-8.147-8.085s-8.146 3.62-8.146 8.086c0 1.312.32 2.553.882 3.647l7.264 13.01 7.265-13.01a7.99 7.99 0 0 0 .881-3.647Z" />
      <path d="M35.25 17.51c0 1.7-1.393 3.084-3.106 3.084-1.714 0-3.107-1.383-3.107-3.084 0-1.7 1.393-3.083 3.107-3.083 1.713 0 3.106 1.383 3.106 3.084ZM13.818 7.21c0-3.59-2.935-6.504-6.553-6.504S.71 3.619.71 7.21c0 1.059.263 2.053.71 2.935l5.844 10.47 5.843-10.47c.454-.882.71-1.876.71-2.935Z" />
      <path d="M9.76 7.112a2.492 2.492 0 0 1-2.502 2.483 2.487 2.487 0 0 1-2.502-2.483 2.487 2.487 0 0 1 2.502-2.484A2.487 2.487 0 0 1 9.76 7.112Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h41v35H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PathButton;
