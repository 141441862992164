import React from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/CalendarIcon.svg";
import { ReactComponent as CollaborationIcon } from "../../../assets/CollaborationIcon.svg";
import { ReactComponent as EarnIcon } from "../../../assets/EarnIcon.svg";
import { ReactComponent as EarnMedalIcon } from "../../../assets/EarnMedalIcon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/LocationIcon.svg";
import { ReactComponent as RedeemIcon } from "../../../assets/RedeemIcon.svg";
import {
  AboutContainer,
  AboutElement,
  AboutElementIcon,
  AboutElementsContainer,
  AboutElementText,
  AboutText,
} from "./style";
import { t } from "i18next";

const About = ({ variant }) => {
  const aboutElements = [
    {
      icon: <CalendarIcon />,
      text: t("experienceRuns"),
    },
    {
      icon: <CollaborationIcon />,
      text: t("openForAll"),
    },
    {
      icon: <LocationIcon />,
      text: t("activateAR"),
    },
    {
      icon: <RedeemIcon />,
      text: t("XPointsEarned"),
    },
    {
      icon: <EarnMedalIcon />,
      text: t("getXPoints"),
    },
    {
      icon: <EarnIcon />,
      text: t("multipleExperience"),
    },
  ];

  return (
    <AboutContainer>
      <AboutText variant={variant}>
        {t("aboutTheHeartExperience").split("BREAK").join(" ")}
      </AboutText>
      <AboutElementsContainer>
        {aboutElements.map((element, index) => (
          <AboutElement key={index}>
            <AboutElementIcon>{element.icon}</AboutElementIcon>
            <AboutElementText variant={variant}>
              {element.text}
            </AboutElementText>
          </AboutElement>
        ))}
      </AboutElementsContainer>
    </AboutContainer>
  );
};

export default About;
