import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const SignupBannerContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  margin-top: 5vw;
  padding: 1vw;
  height: 100%;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  display: grid;
  grid-template-columns: 4fr 2fr;
  align-items: baseline;
  position: relative;
`;

export const SignupBannerText = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 2vw;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Classic", sans-serif;
    font-style: normal;
    text-align: left;
    text-transform: uppercase;
  }
`;

export const SignupButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
`;

export const SignupButton = styled(Box)`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
  border: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
  cursor: pointer;
  padding: 1.2vw;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0px;
  font-family: "Standard", sans-serif;
  font-style: normal;
  cursor: pointer;
`;

export const SubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 0.9vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-decoration: underline;
    cursor: pointer;
  }
`;
