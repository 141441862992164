import React from "react";
const img = require(`../../../assets/Desktop/FootLockerLogo.png`);

const DesktopLogo = () => {
  return (
    <img
      src={img}
      style={{
        justifySelf: "center",
        position: "absolute",
        left: "43.5vw",
        width: "12vw",
        alignSelf: "center",
      }}
      alt="Foot Locker Logo"
    />
  );
};

export default DesktopLogo;
