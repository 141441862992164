import React from "react";
import { ReactComponent as Share } from "../../../assets/Share.svg";
import {
  Container,
  IconContainer,
  SubText,
  Title,
  TitleContainer,
  ShareButton,
} from "./style";
import { t } from "i18next";

const ShareBanner = ({ variant }) => {
  return (
    <Container>
      <IconContainer>
        <Share />
      </IconContainer>
      <TitleContainer>
        <Title variant={variant}>
          {t("enjoyEvenMore")
            .split("BREAK")
            .map((data, idx) =>
              !!idx ? (
                <>
                  {data} <br />
                </>
              ) : (
                data
              )
            )}
        </Title>
        <SubText variant={variant}>{t("experienceItWithAFriend")}</SubText>
      </TitleContainer>
      <ShareButton
        onClick={async () => {
          await navigator.share({
            title: t("shareTitle"),
            text: t("shareText"),
            url: "http://www.theheartexperience.com",
          });
        }}
        variant={variant}
      >
        {t("shareWithAFriend")}
      </ShareButton>
    </Container>
  );
};

export default ShareBanner;
