import { useGLTF } from "@react-three/drei";

const FLMap = () => {
	const { nodes, materials } = useGLTF("./map_assets/map.glb");

	const terrainColor = "#000000";
	const streetColor = "#ffffff";
	const streetEmissive = "#ffffff";
	const streetEmissiveIntensity = "1.5";
	const quartersColor = "#585858";
	const waterColor = "#3e3e3e";
	const buildsColor = "#dc281e";

	return (
		<group dispose={null}>
			<mesh geometry={nodes.terrain_geo.geometry}>
				<meshBasicMaterial color={terrainColor} receiveShadow />
			</mesh>
			<mesh geometry={nodes.quarters_geo.geometry} material-color={quartersColor} receiveShadow />
			<mesh geometry={nodes.street_geo.geometry}>
				<meshStandardMaterial
					color={streetColor}
					emissive={streetEmissive}
					emissiveIntensity={streetEmissiveIntensity}
				/>
			</mesh>
			<mesh
				geometry={nodes.water_geo.geometry}
				material={materials.water_mat}
				material-color={waterColor}
				material-emissive={waterColor}
				materialIntensity={0.5}
				receiveShadow
			/>
			<mesh
				geometry={nodes.build_arc_of_triumph_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.build_eiffel_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.build_invalides_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.build_notre_dame_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.build_pantheon_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_rue_pierre_lescot_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_westfield_les_temps.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_igor_stravinsky_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_commercial_center_beaugrenelle_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_champs_elysees_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_lazare_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_cladel_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_rue_de_rivoli.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_boulevard_de_la_chapelle_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_saint_antoine_geo.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>

			<mesh
				geometry={nodes.place_45_rue_de_rivoli.geometry}
				material={materials.builds_mat}
				material-color={buildsColor}
			>
				<meshStandardMaterial color={buildsColor} emissive={buildsColor} emissiveIntensity={0.5} />
			</mesh>
		</group>
	);
};

export default FLMap;
