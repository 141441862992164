import { Box } from "@mui/material";
import styled from "styled-components";

export const VideoContainer = styled(Box)`
  width: 65%;
  margin: 0 auto;
  margin-top: 2vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ImgContainer = styled(Box)`
  width: 65vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 5vw;
`;

export const VideoPlay = styled(Box)`
  width: 8vw;
  position: absolute;
  top: 13vw;
  left: 28.5vw;
`;
