import { t } from "i18next";
import React from "react";
import { BonusTypes } from "../../../utils/utils";
import {
  BonusBox,
  BonusContainer,
  BonusImageContainer,
  BonusText,
  BonusTitle,
  Container,
} from "./style";
import { useSelector } from "react-redux";

const Bonus = ({ variant }) => {
  const { activities } = useSelector((state) => state.user);
  return (
    <BonusContainer>
      <BonusTitle variant={variant}>{t("bonusCollected")}</BonusTitle>
      <Container>
        {BonusTypes.map((data) => (
          <BonusBox key={data.label}>
            <BonusImageContainer
              unlocked={activities && data.unlockFunction(activities)}
            >
              {React.cloneElement(data.icon, {
                variant: variant,
              })}
            </BonusImageContainer>
            <BonusText
              variant={variant}
              unlocked={activities && data.unlockFunction(activities)}
            >
              {t(data.label)}
            </BonusText>
          </BonusBox>
        ))}
      </Container>
    </BonusContainer>
  );
};

export default Bonus;
