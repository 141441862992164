import { api } from "./axiosConfig";

export class AuthService {
  static register = async (data) => {
    const response = await api.post("/users", {
      mailAddress: data.toLowerCase(),
      password: "1234",
    });
    return response;
  };
  static authenticate = async (data) => {
    const response = await api.post("/tokens", data, {
      auth: {
        username: data.toLowerCase(),
        password: "1234",
      },
    });
    return response;
  };

  static refreshToken = async () => {
    const response = await api.post("/tokens/refresh", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
    });
    return response;
  };

  static tokensRevoke = async () => {
    const response = await api.post("/tokens/revoke", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response;
  };
}
