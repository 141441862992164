import React from "react";
import { ReactComponent as Share } from "../../../assets/Share.svg";
import {
  Container,
  IconContainer,
  SubText,
  Title,
  TitleContainer,
  ShareButton,
} from "./style";
import { t } from "i18next";

const ShareBanner = ({ variant }) => {
  return (
    <Container>
      <IconContainer>
        <Share />
      </IconContainer>
      <TitleContainer>
        <Title variant={variant}>{t("enjoyEvenMore")}</Title>
        <SubText variant={variant}>{t("experienceItWithAFriend")}</SubText>
      </TitleContainer>
      <ShareButton variant={variant}>{t("shareWithAFriend")}</ShareButton>
    </Container>
  );
};

export default ShareBanner;
