import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const SignupBannerContainer = styled(Box)`
  width: 90%;
  margin: 0 auto;
  margin-top: 10vw;
  padding: 1vw;
  height: 100%;
  background-color: ${({ variant, theme }) =>
    theme?.[variant]?.backgroundColor};
  color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10vw;
`;

export const SignupBannerText = styled(Typography)`
  &.MuiTypography-root {
    width: 95%;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    font-size: 5vw;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Classic", sans-serif;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const SignupButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5vw;
  text-align: center;
`;

export const SignupButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
  border: 1px solid ${({ variant, theme }) => theme?.[variant]?.textColor};
  cursor: pointer;
  padding: 2.5vw;
  border-radius: 1vw;
  font-size: 4vw;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0px;
  font-family: "Standard", sans-serif;
  font-style: normal;
  cursor: pointer;
`;

export const SubText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-decoration: underline;
    cursor: pointer;
  }
`;
