import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DesktopView from "./components/DesktopComponents/DesktopView/DesktopView";
import LanguageModal from "./components/MobileComponents/LanguageModal/LanguageModal";
import MobileView from "./components/MobileComponents/MobileView/MobileView";
import QRModal from "./components/QRModal/QRModal";
import "./fonts/FootLocker-FLClassic.otf";
import { useMobile } from "./hooks/useMobile";
import "./index.css";
import { refreshToken } from "./store/authSlice/authAsyncThunk";
import { setAccessToken, setExpiresAt } from "./store/authSlice/authSlice";
import {
  getActivities,
  getUser,
  postEvent,
} from "./store/userSlice/userAsyncThunk";
import { setInstructionsModal, setLanguage } from "./store/userSlice/userSlice";
import { theme } from "./utils/utils";

function App() {
  const variant = "dark";
  const [started, setStarted] = useState(
    new Date() > new Date(2024, 6, 26, 0, 0, 0, 0)
  );

  useEffect(() => {
    let interval;
    if (!started) {
      interval = setInterval(() => {
        setStarted(new Date() > new Date(2024, 6, 26, 0, 0, 0, 0));
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [started]);

  const { activities, signUpModal, qrModal } = useSelector(
    (state) => state.user
  );

  const isMobile = useMobile();

  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.user);

  /* eslint-disable */
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const expiresAt = localStorage.getItem("expiresAt");
    const language = localStorage.getItem("language");
    const instructionsModal = localStorage.getItem("instructionsModal");
    if (instructionsModal) {
      dispatch(setInstructionsModal(instructionsModal));
    } else {
      dispatch(setInstructionsModal(true));
    }
    if (language) {
      dispatch(setLanguage(language));
    } else {
      dispatch(setLanguage("en"));
    }
    if (expiresAt) {
      dispatch(setExpiresAt(expiresAt));
    }
    if (accessToken && expiresAt && new Date() < new Date(expiresAt)) {
      dispatch(setAccessToken(accessToken));
      dispatch(setExpiresAt(expiresAt));
      dispatch(getUser());
      dispatch(getActivities());
    } else if (accessToken) {
      dispatch(refreshToken()).then(() => {
        dispatch(getUser());
        dispatch(getActivities());
      });
    }
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
    document.querySelector("[name='description']").content = t("shareTitle");
  }, [language, i18n]);

  useEffect(() => {
    if (!activities?.length) {
      dispatch(getActivities());
    }
  }, [activities]);

  useEffect(() => {
    const eventRegistered = !!localStorage.getItem("eventRegistered");
    if (!eventRegistered) {
      dispatch(
        postEvent({
          eventType: "visualization",
          userAgent: navigator.userAgent,
          language: navigator.language,
        })
      );
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme?.[variant].backgroundColor,
        position: "relative",
      }}
      className="App"
    >
      <LanguageModal open={signUpModal !== ""} />
      {qrModal !== "" && (
        <QRModal
          open={qrModal !== ""}
          experienceId={parseInt(qrModal?.split("ACT")?.[1])}
        />
      )}
      {isMobile ? (
        <MobileView variant={variant} started={started} />
      ) : (
        <DesktopView variant={variant} started={started} />
      )}
    </div>
  );
}

export default App;
