import { Checkbox, FormControlLabel } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setInstructionsModal } from "../../../store/userSlice/userSlice";
import { bulletPoints } from "../../../utils/utils";
import {
  BulletPoint,
  BulletPointIconContainer,
  BulletPointNumber,
  BulletPointText,
  BulletPointTextContainer,
  BulletsPointContainer,
  DontShowItAgainContainer,
  DontShowItAgainText,
  LetsGoButton,
  ModalContainer,
  ModalTitle,
  ModalTitleContainer,
} from "./style";

export const GPSModal = ({ variant }) => {
  const instructionsModal = localStorage.getItem("instructionsModal");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(instructionsModal === "false" ? false : true);
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setInstructionsModal(!active));
    setOpen(false);
  };

  return (
    /* eslint-disable */
    <ModalContainer open={open}>
      <ModalTitleContainer>
        <ModalTitle variant={variant}>{t("modalHowItWorks")}</ModalTitle>
      </ModalTitleContainer>
      <BulletsPointContainer variant={variant}>
        {
          bulletPoints(false).map((data, idx) =>
            <BulletPoint key={idx}>
              <BulletPointTextContainer>
                <BulletPointNumber variant={variant}>
                  {`${idx + 1}.`}
                </BulletPointNumber>
                <BulletPointText variant={variant}>{data?.label}</BulletPointText>
              </BulletPointTextContainer>
              {
                data?.icons.length > 0 &&
                <BulletPointIconContainer>
                  {data?.icons?.map(icon => icon)}
                </BulletPointIconContainer>
              }
            </BulletPoint>
          )
        }
      </BulletsPointContainer>
      <LetsGoButton variant={variant} onClick={onClickHandler}>
        {t("letsBegin")}
      </LetsGoButton>
      <DontShowItAgainContainer>
        <DontShowItAgainText variant={variant}>{t("dontShowAgain")}</DontShowItAgainText>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
              onClick={(e) => setActive(e.target.checked)}
              checked={active}
            />
          }
        />
      </DontShowItAgainContainer>
    </ModalContainer>
  );
};
