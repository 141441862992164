import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const PrizesContainer = styled(Box)`
  width: 65%;
  margin: 0 auto;
  margin-top: 10vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled(Box)`
  margin-top: 3vw;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1vw;
  text-align: left;
  align-items: center;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3vw;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0px;
    font-family: "Classic", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    text-transform: uppercase;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    color: ${({ variant, theme }) => theme?.[variant]?.textColor};
  }
`;

export const ShowThePrizesButton = styled(Typography)`
  &.MuiTypography-root {
    width: fit-content;
    font-size: 1.1vw;
    line-height: 1.2;
    letter-spacing: 0px;
    font-family: "Standard", sans-serif;
    font-weight: 400;
    color: ${({ variant, theme }) => theme?.[variant]?.backgroundColor};
    background-color: ${({ variant, theme }) => theme?.[variant]?.textColor};
    border-radius: 0.5vw;
    padding: 1vw 2vw;
    cursor: pointer;
    position: absolute;
    top: 48vw;
    left: 33.5vw;
  }
`;
