import { api } from "./axiosConfig";

export class UserService {
  static getUser = async () => {
    const response = await api.get("/users/me", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response;
  };

  static deleteUser = async (data) => {
    const response = await api.delete("/users/me", data);
    return response;
  };

  static updateMail = async (data) => {
    const response = await api.put("/mailAddress", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response;
  };

  static updatePassword = async (data) => {
    const response = await api.put("/password", data);
    return response;
  };

  static resetPassword = async (data) => {
    const response = await api.post("/password/reset", data);
    return response;
  };

  static resetPasswordComplete = async (data) => {
    const response = await api.post("/password/reset/complete", data);
    return response;
  };

  static getActivities = async () => {
    const response = await api.get("/activities", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response;
  };

  static getRewards = async () => {
    const response = await api.get("/rewards", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response;
  };

  static markActivity = async (data) => {
    const response = await api.put(
      `/activities/${data}/completed`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return response;
  };

  static postEvent = async (data) => {
    const response = await api.post("/events", data);
    return response;
  };
}
